import { ApiClient } from '@/services/api/apiClient';
import type { AxiosResponse } from 'axios';
import type {
  DeleteReportSettingPayload,
  DeleteReportSettingsResponse,
  EditReportSettingPayload,
  EditReportSettingsResponse,
  FetchReportSettingsListPayload,
  FetchReportSettingsListResponse,
  SaveReportSettingsPayload,
  SaveReportSettingsResponse,
} from './types';

export class ReportSettingsApiClient extends ApiClient {
  async fetchReportSettingsList(
    payload: FetchReportSettingsListPayload,
  ): Promise<AxiosResponse<FetchReportSettingsListResponse>> {
    const { spaceId, projectId, reportKey } = payload;

    const res = await this.httpClient.get<FetchReportSettingsListResponse>(
      `/api/${spaceId}/${projectId}/saved_report_settings/${reportKey}/list`,
    );

    return res;
  }

  async saveReportSettings(
    payload: SaveReportSettingsPayload,
  ): Promise<AxiosResponse<SaveReportSettingsResponse>> {
    const { spaceId, projectId, reportKey, data } = payload;

    const res = await this.httpClient.post<SaveReportSettingsResponse>(
      `/api/${spaceId}/${projectId}/saved_report_settings/${reportKey}/create`,
      data,
    );

    return res;
  }

  async editReportSettings(
    payload: Partial<EditReportSettingPayload>,
  ): Promise<AxiosResponse<EditReportSettingsResponse>> {
    const { spaceId, projectId, reportKey, reportSettingsId, data } = payload;

    const res = await this.httpClient.patch<EditReportSettingsResponse>(
      `/api/${spaceId}/${projectId}/saved_report_settings/${reportKey}/${reportSettingsId}`,
      data,
    );

    return res;
  }

  async deleteReportSettings(
    payload: DeleteReportSettingPayload,
  ): Promise<AxiosResponse<DeleteReportSettingsResponse>> {
    const { spaceId, projectId, reportKey, reportSettingsId } = payload;

    const res = await this.httpClient.delete<DeleteReportSettingsResponse>(
      `/api/${spaceId}/${projectId}/saved_report_settings/${reportKey}/${reportSettingsId}`,
    );

    return res;
  }
}
export const reportSettingsApiClient = new ReportSettingsApiClient();
