import segmentEditor from './segmentEditor';
import accountConnector from './accountConnector';
import segmentMultiplier from './segmentMultiplier';
import segmentObjects from './segmentObjects';

export default {
  selectorNoName: 'NoName',
  accountConnector,
  segmentEditor,
  segmentMultiplier,
  segmentObjects,
};
