import type { LeafPaths } from '@/types';
import { translate, type MessageSchema } from './translate';

export const t = (
  title: LeafPaths<MessageSchema>,
  values?: Record<string, string | number>,
): string => {
  return values ? translate.global.t(title, values) : translate.global.t(title);
};

export default t;
