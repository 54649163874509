<template>
  <div :class="rootClassNames">
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="ntf-group__item"
    >
      <NtfNotification :id="notification.id" :notification="notification" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useClassNames } from '@/packages/ddh-ui-kit';

import NtfNotification from './NtfNotification.vue';

import type { NotifyNotification, NotifyPosition } from '../types';

import { NOTIFY_POSITION_DEFAULT } from '../constants';

const props = withDefaults(
  defineProps<{
    position?: NotifyPosition;
    notifications?: NotifyNotification[];
  }>(),
  {
    position: () => NOTIFY_POSITION_DEFAULT,
    notifications: () => [],
  },
);

const { rootClassNames } = useClassNames({
  name: 'ntf-group',
  classNames: (className) => {
    const [positionY, positionX] = props.position;

    const positionYClassNamesMap: Record<typeof positionY, string> = {
      top: `${className}--position-y-top`,
      center: `${className}--position-y-center`,
      bottom: `${className}--position-y-bottom`,
    };

    const positionXClassNamesMap: Record<typeof positionX, string> = {
      left: `${className}--position-x-left`,
      center: `${className}--position-x-center`,
      right: `${className}--position-x-right`,
    };

    const positionClassNames = [
      positionYClassNamesMap[positionY],
      positionXClassNamesMap[positionX],
    ];

    return [...positionClassNames];
  },
});
</script>

<style lang="scss" scoped>
.ntf-group {
  --transform-translate-x: 0;
  --transform-translate-y: 0;

  position: fixed;
  z-index: $snackbar-z-index;
  display: flex;
  flex-direction: column;
  padding: 8px;
  transform: translate(
    var(--transform-translate-x),
    var(--transform-translate-y)
  );

  &--position-y-top {
    top: 0;
  }

  &--position-y-center {
    --transform-translate-y: -50%;

    top: 50%;
  }

  &--position-y-bottom {
    bottom: 0;
  }

  &--position-x-left {
    left: 0;
    align-items: flex-start;
  }

  &--position-x-center {
    --transform-translate-x: -50%;

    left: 50%;
  }

  &--position-x-right {
    right: 0;
    align-items: flex-end;
  }

  &__item {
    margin: 8px;
  }
}
</style>
