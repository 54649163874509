<template>
  <div class="ntf-root">
    <NtfGroup
      v-for="(group, index) in notifyStore.groups"
      :key="index"
      :position="group.position"
      :notifications="group.list"
    />
  </div>
</template>

<script lang="ts" setup>
import { useNotifyStore } from '../store';

import NtfGroup from './NtfGroup.vue';

const notifyStore = useNotifyStore();
</script>

<style lang="scss" scoped>
.ntf-root {
  position: relative;
}
</style>
