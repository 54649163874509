<template>
  <v-dialog
    v-model="isOpened"
    :class="rootClassNames"
    :width="width"
    :height="sidebar ? '100%' : height"
    :min-height="sidebar ? '100%' : minHeight"
    scrollable
    :persistent="persistent || submitting"
  >
    <v-form
      :class="wrapperClassNames"
      :validate-on="formValidateOn"
      @submit.prevent="onSubmit"
    >
      <div :class="headerClassNames">
        <slot name="header" v-bind="slotProps">
          <div :class="headerWrapperClassNames">
            <v-progress-linear
              v-if="fetching"
              class="mdl-modal__loader"
              color="primary"
              absolute
              indeterminate
            />
            <slot name="header-wrapper-content">
              <div class="mdl-modal__header-sidebar-left">
                <v-icon
                  v-if="headerIcons.prepend"
                  v-bind="headerIcons.prepend"
                />
              </div>
              <div class="mdl-modal__header-sidebar-top">
                <slot name="header-icon-top">
                  <v-icon v-if="headerIcons.top" v-bind="headerIcons.top" />
                </slot>
              </div>
              <div class="mdl-modal__header-wrapper-content">
                <slot name="header-content">
                  <div
                    v-if="title"
                    class="mdl-modal__header-title text-h7 text-black"
                    v-html="DOMPurify(title)"
                  />
                  <div
                    v-if="subtitle"
                    :class="headerSubtitleClassNames"
                    v-html="DOMPurify(subtitle)"
                  />
                </slot>
              </div>
              <div class="mdl-modal__header-sidebar-right">
                <slot name="header-sidebar-right-content" />
              </div>
            </slot>
            <CvBtn
              v-if="closable && !persistent"
              class="mdl-modal__btn mdl-modal__btn--close"
              color="grey-lighten-1"
              variant="text"
              icon="$ddh-close-circle-filled"
              :disabled="submitting"
              @click="close"
            />
          </div>
        </slot>
        <slot name="header-append" />
      </div>
      <div v-if="loading" class="mdl-modal__loader">
        <v-progress-circular color="primary" indeterminate />
      </div>
      <slot v-else name="main" v-bind="slotProps">
        <div class="mdl-modal__main">
          <slot name="main-content">
            <div class="mdl-modal__main-content" v-html="DOMPurify(content)" />
          </slot>
        </div>
      </slot>
      <slot name="footer" v-bind="slotProps">
        <div class="mdl-modal__footer flex-shrink-0">
          <slot name="footer-content">
            <CvBtn
              v-for="(button, idx) in buttons"
              :key="idx"
              class="mdl-modal__btn"
              v-bind="button.attrs"
            >
              <div v-html="DOMPurify(button.content)" />
            </CvBtn>
          </slot>
        </div>
      </slot>
    </v-form>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, computed, watch, useSlots } from 'vue';

import type { SubmitEventPromise } from 'vuetify';
import { VForm } from 'vuetify/components/VForm';

import { useClassNames } from '@/packages/ddh-ui-kit';

import DOMPurify from '@/utils/DOMPurify';
import {
  ModallyModalType,
  type MdlModalIconAttrs,
  type ModallyModalButton,
} from '../../types';

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    type?: `${ModallyModalType}`;
    width?: string | number;
    height?: string | number;
    minHeight?: string | number;
    title?: string;
    subtitle?: string;
    header?: {
      align?: 'left' | 'center';
      prependIcon?: string | MdlModalIconAttrs;
      topIcon?: string | MdlModalIconAttrs;
      withoutBorderBottom?: boolean;
    };
    content?: string;
    persistent?: boolean;
    loading?: boolean;
    fetching?: boolean;
    submitting?: boolean;
    buttons?: ModallyModalButton[];
    closable?: boolean;
    sidebar?: boolean;
  }>(),
  {
    modelValue: false,
    type: ModallyModalType.Default,
    width: 'auto',
    height: 'auto',
    minHeight: 'auto',
    title: '',
    subtitle: '',
    header: () => ({}),
    content: '',
    persistent: false,
    loading: false,
    fetching: false,
    submitting: false,
    buttons: () => [],
    closable: false,
    sidebar: false,
  },
);

const emit = defineEmits<{
  (eventName: 'update:modelValue', val: boolean): void;
  (eventName: 'open'): void;
  (eventName: 'close'): void;
  (eventName: 'submit', e: SubmitEventPromise): void;
}>();

const slots = useSlots();

const formValidateOn = ref<'input' | 'submit'>('submit');

const mainSlotsAreEmpty = computed(() => !slots.main && !slots['main-content']);

const {
  rootClassNames,
  headerClassNames,
  headerWrapperClassNames,
  headerSubtitleClassNames,
  wrapperClassNames,
} = useClassNames({
  name: 'mdl-modal',
  classNames: (className) => {
    const typeClassName = `${className}--${props.type}`;
    const sidebarClassName = props.sidebar ? `${className}--sidebar` : '';

    const emptyClassName = mainSlotsAreEmpty.value
      ? `${className}--main-empty`
      : '';

    return [typeClassName, sidebarClassName, emptyClassName];
  },
  elements: {
    header: (className) => {
      const alignClassNamesMap: Record<
        NonNullable<typeof props.header.align>,
        string
      > = {
        left: `${className}--align-left`,
        center: `${className}--align-center`,
      };

      const alignClassName = props.header.align
        ? alignClassNamesMap[props.header.align]
        : '';

      const closableClassName = props.closable ? `${className}--closable` : '';

      const withoutBorderBottomClassName = props.header.withoutBorderBottom
        ? `${className}--without-border-bottom`
        : '';

      return [
        'flex-shrink-0',
        alignClassName,
        closableClassName,
        withoutBorderBottomClassName,
      ];
    },
    headerWrapper: () => [],
    headerSubtitle() {
      const typeClassNamesMap: Record<typeof props.type, string> = {
        default: '',
        confirm: 'text-grey-darken-1',
      };

      const typeClassName = `${typeClassNamesMap[props.type]} `;

      return ['mt-2', typeClassName];
    },
    wrapper() {
      return ['d-flex', 'flex-column', 'text-body-2', 'bg-white'];
    },
  },
});

const headerIcons = computed(() => {
  const icons: {
    prepend: MdlModalIconAttrs | null;
    top: MdlModalIconAttrs | null;
  } = {
    prepend: null,
    top: null,
  };

  const initIconAttrs = (iconOption: string | MdlModalIconAttrs) => {
    const defaultIconAttrs: Partial<MdlModalIconAttrs> = {
      size: 48,
    };

    const iconAttrs = {
      ...defaultIconAttrs,
      ...(typeof iconOption === 'string' ? { icon: iconOption } : iconOption),
    };

    return iconAttrs;
  };

  if (props.header.prependIcon) {
    icons.prepend = initIconAttrs(props.header.prependIcon);
  }

  if (props.header.topIcon) {
    icons.top = initIconAttrs(props.header.topIcon);
  }

  return icons;
});

const isOpened = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const close = () => {
  isOpened.value = false;
};

watch(isOpened, (val) => {
  if (val) {
    const { activeElement } = document;

    if (activeElement instanceof HTMLElement) {
      activeElement.blur();
    }

    emit('open');
  } else {
    emit('close');
  }
});

const slotProps = computed(() => ({
  close,
}));

const onSubmit = (e: SubmitEventPromise) => {
  formValidateOn.value = 'input';

  emit('submit', e);
};

defineExpose({
  isOpened,
  close,
});
</script>

<style lang="scss" scoped>
.mdl-modal {
  --v-overlay-content-max-height: calc(100% - 48px);
  --v-overlay-content-margin: 24px;
  --wrapper-border-radius: 12px;
  --header-min-height: 63px;
  --header-padding: 16px 16px 15px;
  --header-border-bottom: none;
  --main-padding: 0 16px;
  --main-margin-bottom: 0;
  --footer-padding: 0;
  --footer-margin-top: 0;
  --footer-border-top: none;

  justify-content: center;

  &--default {
    --header-border-bottom: 1px solid var(--grey-lighten-3);
    --footer-padding: 16px;
    --footer-border-top: 1px solid var(--grey-lighten-3);
  }

  &--confirm {
    --header-min-height: 64px;
    --header-padding: 24px;
    --main-padding: 0 24px;
    --main-margin-bottom: 24px;
    --footer-padding: 0 24px 24px;
  }

  &--sidebar {
    --v-overlay-content-max-height: 100%;
    --v-overlay-content-margin: 0;
    --wrapper-border-radius: 0;
    --header-padding: 20px 16px;
    --main-padding: 16px;

    justify-content: flex-end;
  }

  &--main-empty {
    --main-margin-bottom: 0;
  }

  &__wrapper {
    flex-grow: 1;
    height: 100%;
    overflow-x: hidden;
    border-radius: var(--wrapper-border-radius);
  }

  &__loader {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  &__header {
    --header-align-items: start;
    --header-text-align: left;

    position: relative;
    flex-shrink: 0;
    border-bottom: var(--header-border-bottom);

    &--align-center {
      --header-align-items: center;
      --header-text-align: center;
    }

    &--without-border-bottom {
      --header-border-bottom: none;
    }

    &-wrapper {
      display: grid;
      grid-template-rows: max-content 1fr;
      grid-template-columns: max-content 1fr;
      align-items: center;
      min-height: var(--header-min-height);
      padding: var(--header-padding);

      &-content {
        display: flex;
        flex-direction: column;
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        align-items: var(--header-align-items);
        justify-content: var(--header-align-items);
      }
    }

    &-sidebar {
      &-left {
        display: flex;
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        align-items: center;
        margin-right: 16px;

        &:empty {
          margin-right: 0;
        }
      }

      &-top {
        display: flex;
        grid-row: 1 / 2;
        grid-column: 1 / 4;
        justify-content: var(--header-align-items);
        margin-bottom: 20px;

        &:empty {
          margin-bottom: 0;
        }
      }

      &-right {
        display: flex;
        grid-row: 2 / 3;
        grid-column: 3 / 4;
        justify-content: var(--header-align-items);
        margin-right: 36px;
        margin-left: 16px;

        &:empty {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

    &-title {
      text-align: var(--header-text-align);
    }

    &-subtitle {
      text-align: var(--header-text-align);
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: var(--main-padding);
    margin-bottom: var(--main-margin-bottom);
    overflow-y: auto;

    &-content {
      flex-grow: 1;
    }
  }

  &__footer {
    display: flex;
    flex-shrink: 0;
    padding: var(--footer-padding);
    border-top: var(--footer-border-top);

    &:empty {
      padding: 0;
      border-top: none;
    }
  }

  &__btn {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }

    &--close {
      position: absolute;
      top: 16px;
      right: 10px;
    }
  }

  ::v-deep {
    .v-overlay {
      &__content {
        max-height: var(--v-overlay-content-max-height);
        margin: var(--v-overlay-content-margin);
      }
    }
  }
}
</style>
