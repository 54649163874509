import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';

export * from './ui';
export * from './user';
export * from './spaces';
export * from './projects';

const store = createPinia();

store.use(
  createPersistedState({
    key: (storeKey) => `ddh-store-v1-${storeKey}`,
  }),
);

export default store;
