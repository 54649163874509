<template>
  <v-snackbar
    v-model="isShown"
    :class="rootClassNames"
    :attach="true"
    :timeout="timeout"
    :color="color"
    variant="elevated"
  >
    <div class="d-flex align-center">
      <div v-if="elevated" class="ntf-notification__icons">
        <v-icon v-if="icon" :icon="icon" :color="color" />
      </div>
      <div class="flex-grow-1">
        <div class="text-subtitle-1">
          {{ notification.title }}
        </div>
        <div class="text-body-1">
          {{ notification.content }}
        </div>
      </div>
      <div v-if="closable" class="ntf-notification__actions flex-shrink-0">
        <CvBtn
          v-if="elevated"
          class="ntf-notification__btn ntf-notification__btn--close px-2"
          color="white"
          variant="text"
          density="comfortable"
          @click="close"
        >
          <span class="text-subtitle-1">Закрыть</span>
        </CvBtn>
        <CvBtn
          v-else
          class="ntf-notification__btn ntf-notification__btn--close"
          color="grey-lighten-2"
          variant="text"
          density="comfortable"
          icon="$ddh-close-circle-filled"
          @click="close"
        />
      </div>
    </div>
  </v-snackbar>
</template>

<script lang="ts" setup>
import { toRefs, computed } from 'vue';

import { useClassNames } from '@/packages/ddh-ui-kit';

import { useNotifyStore } from '../store';

import {
  NotifyNotificationStatus,
  type NotifyNotificationConfig,
} from '../types';

const props = withDefaults(
  defineProps<{
    id?: string | null;
    notification: NotifyNotificationConfig;
  }>(),
  {
    id: null,
  },
);

const { id, notification } = toRefs(props);

const notifyStore = useNotifyStore();

const status = computed(
  () => notification.value.status || NotifyNotificationStatus.Neutral,
);

const timeout = computed(() => notification.value.timeout || 6000);

const elevated = computed(() => notification.value.elevated || false);
const block = computed(() => notification.value.block || false);
const closable = computed(() => notification.value.closable || false);

const { rootClassNames } = useClassNames({
  name: 'ntf-notification',
  classNames: (className) => {
    const statusClassName = `${className}--${status.value}`;

    const elevatedClassName = elevated.value ? `${className}--elevated` : '';

    const blockClassName = block.value ? `${className}--block` : '';

    return [statusClassName, elevatedClassName, blockClassName];
  },
});

const close = () => {
  if (!id.value) return;

  notifyStore.removeNotification(id.value);
};

const isShown = computed({
  get() {
    return true;
  },
  set(value) {
    if (!value) close();
  },
});

const color = computed(() => {
  const colors: Record<NotifyNotificationStatus, string> = {
    neutral: 'black',
    success: 'success',
    warning: 'warning',
    error: 'error',
  };

  return elevated.value ? colors[status.value] : 'white';
});

const icon = computed(() => {
  const icons: Record<NotifyNotificationStatus, string> = {
    neutral: '$ddh-warning',
    success: '$ddh-check',
    warning: '$ddh-warning',
    error: '$ddh-error',
  };

  return elevated.value ? icons[status.value] : null;
});
</script>

<style lang="scss" scoped>
.ntf-notification {
  --actions-margin-right: -6px;
  --actions-margin-left: 10px;
  --v-snackbar-wrapper-padding-left: 12px;
  --v-snackbar-wrapper-border-color: var(--grey-lighten-2);
  --v-snackbar-wrapper-border-left: 12px solid
    var(--v-snackbar-wrapper-border-color);

  position: static;

  &--neutral {
    --v-snackbar-wrapper-border-color: var(--grey-lighten-2);
  }

  &--success {
    --v-snackbar-wrapper-border-color: var(--success-base);
  }

  &--warning {
    --v-snackbar-wrapper-border-color: var(--warning-base);
  }

  &--error {
    --v-snackbar-wrapper-border-color: var(--error-base);
  }

  &--elevated {
    --actions-margin-right: -8px;
    --actions-margin-left: 8px;
    --v-snackbar-wrapper-padding-left: 16px;
    --v-snackbar-wrapper-border-left: none;
  }

  &--block {
    display: block;
  }

  &__icons {
    margin-right: 16px;
  }

  &__actions {
    margin-right: var(--actions-margin-right);
    margin-left: var(--actions-margin-left);
  }
}

::v-deep .v-snackbar {
  &__wrapper {
    position: static;
    border-left: var(--v-snackbar-wrapper-border-left);
    transform: translateX(0) !important;
  }

  &__content {
    padding-left: var(--v-snackbar-wrapper-padding-left);
  }
}
</style>
