export default {
  addCabinet: 'Add a cabinet',
  needAddCabinet:
    'To display reports you need to<br />connect advertising offices',
  noCabinets: 'The project has no advertising cabinets',
  show: 'Show',
  hide: 'Hide',
  chart: 'chart',
  chartSettings: 'Customize chart',
  filters: 'Filters',
  tableSettings: 'Customize table',
  allAvailable: 'All available',
  notSet: 'Not selected',
  noName: 'NoName',
  magicLink: 'MagicLink',
  download: 'Download',
  cabinetPlural: 'no cabinets|cabinet|cabinets',
};
