export default {
  name: 'Name',
  myCollection: 'MyCollection',
  cancel: 'Cancel',
  save: 'Save',
  newCollection: 'New Collection',
  multiplierType: 'MultiplierType',
  fix: 'Fixed Amount',
  coef: 'Coefficient',
  periodActive: 'Action Period',
  startPeriod: 'Start of period',
};
