import { computed, type ComputedRef } from 'vue';

import { kebabCase } from 'lodash';

import type { KebabCase } from '@/types';

interface UseClassNamesPayload<RootName extends string, ElName extends string> {
  name: RootName;
  classNames?: (className: KebabCase<RootName>) => string[];
  elements?: {
    [Key in ElName]: (
      className: `${KebabCase<RootName>}__${KebabCase<Key>}`,
    ) => string[];
  };
}

type UseClassNamesReturn<RootName extends string, ElName extends string> = {
  rootClassNames: ComputedRef<[`${KebabCase<RootName>}`, ...string[]]>;
} & {
  [Key in ElName as `${Key}ClassNames`]: ComputedRef<
    [`${KebabCase<RootName>}__${KebabCase<Key>}`, ...string[]]
  >;
};

const useClassNames = <RootName extends string, ElName extends string>(
  payload: UseClassNamesPayload<RootName, ElName>,
): UseClassNamesReturn<RootName, ElName> => {
  type RootClassName = KebabCase<RootName>;

  type ElClassName<T extends string> = `${RootClassName}__${KebabCase<T>}`;

  const rootClassName = kebabCase(payload.name) as RootClassName;

  const rootClassNames = computed<[`${RootClassName}`, ...string[]]>(() => {
    const classNames = payload.classNames
      ? payload.classNames(rootClassName)
      : [];

    return [`${rootClassName}`, ...classNames];
  });

  const entries = Object.entries(payload.elements || {}).map(([key, val]) => {
    const elName = key as ElName;
    const elFn = val as NonNullable<(typeof payload)['elements']>[ElName];

    const elClassName = `${rootClassName}__${kebabCase(
      elName,
    )}` as ElClassName<ElName>;

    return [
      `${elName}ClassNames`,
      computed(() => [elClassName, ...elFn(elClassName)]),
    ];
  });

  const elements: {
    [Key in ElName as `${Key}ClassNames`]: ComputedRef<
      [ElClassName<Key>, ...string[]]
    >;
  } = Object.fromEntries(entries);

  return {
    rootClassNames,
    ...elements,
  };
};

export { useClassNames };
export type { UseClassNamesPayload, UseClassNamesReturn };

export default { useClassNames };
