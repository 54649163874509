import { computed, type Ref } from 'vue';

import type { RawUserMe } from '@/services/api';

import { getUserInitials } from '@/utils';

import { useUserMeQuery } from './queries';

export const useUserMe = (): {
  userMe: Ref<RawUserMe | null>;
  userMeInitials: Ref<string>;
} => {
  const { data: userMe } = useUserMeQuery();

  const userMeInitials = computed(() =>
    getUserInitials(
      userMe.value?.given_name || '',
      userMe.value?.family_name || '',
    ),
  );

  return {
    userMe,
    userMeInitials,
  };
};

export default { useUserMe };
