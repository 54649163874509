export default {
  name: 'Name',
  myCollection: 'MyCollection',
  cancel: 'Cancel',
  save: 'Save',
  newCollection: 'New Collection',
  multiplierType: 'MultiplierType',
  fix: 'Fixed Amount',
  coef: 'Coefficient',
  available: 'Available',
  periodActive: 'Effective period',
  startPeriod: 'Start of period',
  endPeriod: 'End of period',
  periodError:
    'The specified date range overlaps with previously created dates',
  requiredField: 'Required field',
  errorDate: 'The start date must be earlier than the end date',
  errorDateRotate: 'The end date must be earlier than the start date',
  newMultiplier: 'New financial multiplier',
  editMultiplier: 'Edit financial multiplier',
  add: 'Add',
  multipliers: 'Set financial multipliers',
  segmentNoAddCost: 'Segment does not have a markup',
  addTip: 'You can add financial multipliers',
  deleteMultiplier: 'Delete a multiplier?',
  deleteMultiplierTip: 'All multiplier settings will be lost',
  delete: 'Delete',
};
