import type { AdsConnectorType, ReportKey, ValueType } from '@/services/api';

export enum ReportFilterOperationType {
  MoreOrEqual = 'moreOrEqual',
  LessOrEqual = 'lessOrEqual',
  Equal = 'equal',
  StartWith = 'startWith',
  Include = 'include',
}
export enum ReportFilterOperandType {
  MoreOrEqual = '>=',
  More = '>',
  LessOrEqual = '<=',
  Less = '<',
  Equal = '=',
  NotEqual = '<>',
  Like = '<like>',
  NotLike = '<!like>',
}
export enum ReportFilterLogicalOperandType {
  And = ';',
  Or = ',',
}
export interface ReportFilterOperation {
  type: ReportFilterOperationType;
  name: string;
  operand: ReportFilterOperandType;
  negativeOperand: ReportFilterOperandType;
  prefixValue: string;
  postfixValue: string;
}

export interface ReportFilter {
  enabled: boolean;
  field: string;
  operation: ReportFilterOperation;
  logicalOperand: ReportFilterLogicalOperandType;
  value: string;
}
export interface Report {
  id: number | null;
  name: string;
  key: ReportKey;
  adsConnectorType: AdsConnectorType;
  grants: {
    edit: boolean;
    remove: boolean;
  };
}

export interface ReportListItem {
  key: ReportKey;
  name: string;
  type: AdsConnectorType;
  image: string | null;
}

export interface ReportGroup {
  id: string;
  title: string;
  description?: string;
}
export interface ReportField {
  id: string;
  name: string;
  title: string;
  description: string;
  groups: ReportGroup[];
  values?: Record<string, string>;
  hidden: boolean;
  value_type: ValueType;
}

export enum ReportChartDatasetTypeKey {
  Bar = 'bar',
  Line = 'line',
}

export enum ReportChartDatasetPositionKey {
  Left = 'left',
  Right = 'right',
}

export interface ReportChartDatasetConfig {
  type: ReportChartDatasetTypeKey;
  color: string;
  position: ReportChartDatasetPositionKey;
  field: ReportField;
}
