import type { Plugin, Component } from 'vue';

import { upperFirst, camelCase } from 'lodash';

const files = import.meta.glob<{ default: Component }>('@/layouts/*.vue', {
  eager: true,
});

export const layoutsPluginInstall: Plugin = (app) => {
  Object.entries(files).forEach(async ([path, definition]) => {
    const fileName = upperFirst(
      camelCase((path.split('/').pop() || '').replace(/\.\w+$/, '')),
    );

    app.component(fileName, definition.default);
  });
};

export default layoutsPluginInstall;
