import routes from './routes/index';
import reports from './reports';
import components from './components';
import projects from './projects';

export default {
  components,
  reports,
  routes,
  projects,
};
