export default {
  selectDate: 'Выбрать даты',
  select: 'Применить',
  cancel: 'Отмена',
  timeVariation: {
    yesterday: 'Вчера',
    week: 'Неделя',
    month: 'Месяц',
  },
  start: 'Начало',
  end: 'Конец',
  periods: {
    yesterday: 'Вчера',
    last7Days: 'Последние 7 дней',
    last14Days: 'Последние 14 дней',
    last30Days: 'Последние 30 дней',
    thisWeek: 'Текущая неделя',
    prevWeek: 'Прошлая неделя',
    thisMonth: 'Текущий месяц',
    prevMonth: 'Прошлый месяц',
    thisYear: 'Текущий год',
    prevYear: 'Прошлый год',
  },
};
