import { useMutation, type UseMutationReturnType } from '@tanstack/vue-query';
import { userApiClient } from '@/services/api';
import router, { RouteName } from '@/router';

export const useLogoutMutation = (): UseMutationReturnType<
  boolean,
  unknown,
  void,
  unknown
> => {
  return useMutation({
    mutationFn: async () => {
      const res = await userApiClient.logoutUser();

      return res.data.ok;
    },
    onSuccess: () => {
      router.push({
        name: RouteName.Logout,
      });
    },
  });
};
export default { useLogoutMutation };
