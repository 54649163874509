import { computed, type Ref, type MaybeRef } from 'vue';
import { useQuery, type UseQueryReturnType } from '@tanstack/vue-query';

import { get } from 'lodash';

import { userApiClient, type RawUserMe } from '@/services/api';

import { useUserStore } from '@/store';

import { AppQueryKey } from './types';

export const useUserMeQuery = (options?: {
  keepPreviousData?: MaybeRef<boolean>;
  enabled?: MaybeRef<boolean>;
}): Omit<UseQueryReturnType<RawUserMe, unknown>, 'data'> & {
  data: Ref<RawUserMe | null>;
} => {
  const { keepPreviousData, enabled } = { ...options };

  const userStore = useUserStore();

  const query = useQuery({
    queryKey: [AppQueryKey.UserMe],
    queryFn: async () => {
      const res = await userApiClient.fetchUserMe();

      return res.data.data;
    },
    onSuccess: (data) => {
      userStore.setUser(data);
      userStore.setIsAuthenticated(true);
    },
    onError: (error) => {
      if (get(error, 'response.status') !== 401) {
        throw error;
      }

      query.remove();
      userStore.setUser(null);
      userStore.setIsAuthenticated(false);
    },
    keepPreviousData,
    enabled,
    retry: false,
  });

  const { data, ...other } = query;

  return {
    data: computed(() => data.value ?? null),
    ...other,
  };
};

export default { useUserMeQuery };
