import { v4 as uuid } from 'uuid';

import { useNotifyStore } from './store';

import type { NotifyNotification, NotifyPushPayload } from './types';

import { NOTIFY_POSITION_DEFAULT } from './constants';

export const useNotify = (): {
  push: (payload: NotifyPushPayload) => void;
} => {
  const notifyStore = useNotifyStore();

  const push = (payload: NotifyPushPayload) => {
    const notificationId = uuid();

    const notification: NotifyNotification = {
      position: NOTIFY_POSITION_DEFAULT,
      closable: true,
      ...payload,
      id: notificationId,
    };

    notifyStore.addNotification(notification);
  };

  return { push };
};

export default { useNotify };
