import {
  type ReportFilterOperation,
  ReportFilterOperationType,
  ReportFilterOperandType,
} from '@/types';
import { t } from '@/services/translate';

export const toggleMenu = [
  { value: true, text: t('reports.filters.active') },
  { value: false, text: t('reports.filters.noActive') },
];

export const defaultOperation = <ReportFilterOperation>{
  type: ReportFilterOperationType.Equal,
  name: t('reports.filters.operations.equal'),
  operand: ReportFilterOperandType.Equal,
  negativeOperand: ReportFilterOperandType.NotEqual,
  prefixValue: '',
  postfixValue: '',
};
export const filtersOperationOnNumber = <ReportFilterOperation[]>[
  {
    type: ReportFilterOperationType.MoreOrEqual,
    name: t('reports.filters.operations.moreOrEqual'),
    operand: ReportFilterOperandType.MoreOrEqual,
    negativeOperand: ReportFilterOperandType.Less,
    prefixValue: '',
    postfixValue: '',
  },
  {
    type: ReportFilterOperationType.LessOrEqual,
    name: t('reports.filters.operations.lessOrEqual'),
    operand: ReportFilterOperandType.LessOrEqual,
    negativeOperand: ReportFilterOperandType.More,
    prefixValue: '',
    postfixValue: '',
  },
  {
    type: ReportFilterOperationType.Equal,
    name: t('reports.filters.operations.equal'),
    operand: ReportFilterOperandType.Equal,
    negativeOperand: ReportFilterOperandType.NotEqual,
    prefixValue: '',
    postfixValue: '',
  },
];
export const filtersOperationOnString = <ReportFilterOperation[]>[
  {
    type: ReportFilterOperationType.Include,
    name: t('reports.filters.operations.include'),
    operand: ReportFilterOperandType.Like,
    negativeOperand: ReportFilterOperandType.NotLike,
    prefixValue: '%',
    postfixValue: '%',
  },
  {
    type: ReportFilterOperationType.StartWith,
    name: t('reports.filters.operations.startWith'),
    operand: ReportFilterOperandType.Like,
    negativeOperand: ReportFilterOperandType.NotLike,
    prefixValue: '',
    postfixValue: '%',
  },
  {
    type: ReportFilterOperationType.Equal,
    name: t('reports.filters.operations.equal'),
    operand: ReportFilterOperandType.Equal,
    negativeOperand: ReportFilterOperandType.NotEqual,
    prefixValue: '',
    postfixValue: '',
  },
];

export const filtersOperations = [
  {
    type: ReportFilterOperationType.Include,
    name: t('reports.filters.operations.include'),
    operand: ReportFilterOperandType.Like,
    negativeOperand: ReportFilterOperandType.NotLike,
    prefixValue: '%',
    postfixValue: '%',
  },
  {
    type: ReportFilterOperationType.StartWith,
    name: t('reports.filters.operations.startWith'),
    operand: ReportFilterOperandType.Like,
    negativeOperand: ReportFilterOperandType.NotLike,
    prefixValue: '',
    postfixValue: '%',
  },
  {
    type: ReportFilterOperationType.MoreOrEqual,
    name: t('reports.filters.operations.moreOrEqual'),
    operand: ReportFilterOperandType.MoreOrEqual,
    negativeOperand: ReportFilterOperandType.Less,
    prefixValue: '',
    postfixValue: '',
  },
  {
    type: ReportFilterOperationType.LessOrEqual,
    name: t('reports.filters.operations.lessOrEqual'),
    operand: ReportFilterOperandType.LessOrEqual,
    negativeOperand: ReportFilterOperandType.More,
    prefixValue: '',
    postfixValue: '',
  },
  {
    type: ReportFilterOperationType.Equal,
    name: t('reports.filters.operations.equal'),
    operand: ReportFilterOperandType.Equal,
    negativeOperand: ReportFilterOperandType.NotEqual,
    prefixValue: '',
    postfixValue: '',
  },
];
export const filtersOperationOnBool = <ReportFilterOperation[]>[
  {
    type: ReportFilterOperationType.MoreOrEqual,
    name: t('reports.filters.operations.moreOrEqual'),
    operand: ReportFilterOperandType.MoreOrEqual,
    negativeOperand: ReportFilterOperandType.Less,
    prefixValue: '',
    postfixValue: '',
  },
  {
    type: ReportFilterOperationType.Equal,
    name: t('reports.filters.operations.equal'),
    operand: ReportFilterOperandType.Equal,
    negativeOperand: ReportFilterOperandType.NotEqual,
    prefixValue: '',
    postfixValue: '',
  },
];
