export default {
  marketingCollection: 'Рекламные кампании набора',
  available: 'Доступные',
  nothingFounded: 'Ничего не найдено',
  noCabinets: 'Нет рекламных кампаний',
  noResults:
    'Ваш запрос не дал результатов,<br />попробуйте другие ключевые слова',
  allOnSegments: 'Все кампании распределены по сегментам',
  allConnected: 'Привязанные проекту',
  hereShowConnected:
    'Здесь будут отображаться выбранные<br />кампании, пока тут пусто',
  cancel: 'Отмена',
  save: 'Сохранить',
};
