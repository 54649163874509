import { UserRoleKey } from '@/services/api';

import type { UserRolesData } from '@/types';

export const userRolesData: UserRolesData = {
  [UserRoleKey.Admin]: {
    name: 'Администратор',
    description: '',
  },
  [UserRoleKey.Manager]: {
    name: 'Менеджер',
    description:
      'Пользователь может просматривать все кабинеты и добавить новые, приглашать новых пользователей и давать им доступы к просмотру.',
  },
  [UserRoleKey.User]: {
    name: 'Пользователь',
    description: 'Пользователь может просматривать данные\u00a0проекта.',
  },
};

export default { userRolesData };
