export const getUserInitials = (
  firstName: string = '',
  lastName: string = '',
): string => {
  const firstNameChar = firstName[0] || 'J';
  const lastNameChar = lastName[0] || 'D';

  return `${firstNameChar}${lastNameChar}`.toUpperCase();
};

export default { getUserInitials };
