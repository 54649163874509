export enum NotifyPositionY {
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom',
}

export enum NotifyPositionX {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum NotifyNotificationStatus {
  Neutral = 'neutral',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export type NotifyPosition = [NotifyPositionY, NotifyPositionX];

export interface NotifyNotificationConfig {
  position?: NotifyPosition;
  status?: `${NotifyNotificationStatus}`;
  title?: string | number;
  content: string | number;
  timeout?: number;
  elevated?: boolean;
  block?: boolean;
  closable?: boolean;
}

export interface NotifyNotification extends NotifyNotificationConfig {
  id: string;
}

export interface NotifyGroup {
  position: NotifyPosition;
  list: NotifyNotification[];
}

export type NotifyPushPayload = NotifyNotificationConfig;
