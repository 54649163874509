import type { AxiosResponse } from 'axios';

import { gtmClient } from '@/services/gtm';

import { ApiClient } from '../../apiClient';

import type {
  FetchMagicLinksPayload,
  FetchMagicLinksResponse,
  CreateMagicLinkPayload,
  CreateMagicLinkResponse,
  ActivateMagickLinkPayload,
  ActivateMagicLinkResponse,
  DeactivateMagickLinkPayload,
  DeactivateMagicLinkResponse,
  DeleteMagickLinkPayload,
  DeleteMagicLinkResponse,
} from './types';

export class MagicLinkApiClient extends ApiClient {
  async fetchMagicLinks(
    payload: FetchMagicLinksPayload,
  ): Promise<AxiosResponse<FetchMagicLinksResponse>> {
    const { spaceId, projectId } = payload;

    const res = await this.httpClient.get<FetchMagicLinksResponse>(
      `/api/${spaceId}/${projectId}/magic_link/list`,
    );

    return res;
  }

  async createMagicLink(
    payload: CreateMagicLinkPayload,
  ): Promise<AxiosResponse<CreateMagicLinkResponse>> {
    const { spaceId, projectId, data } = payload;

    const res = await this.httpClient.post<CreateMagicLinkResponse>(
      `/api/${spaceId}/${projectId}/magic_link/create`,
      data,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'magic_link_create',
      ddh_space_id: spaceId,
      ddh_project_id: projectId,
    });

    return res;
  }

  async activateMagicLink(
    payload: ActivateMagickLinkPayload,
  ): Promise<AxiosResponse<ActivateMagicLinkResponse>> {
    const { spaceId, projectId, magicLinkId } = payload;

    const res = await this.httpClient.put<ActivateMagicLinkResponse>(
      `/api/${spaceId}/${projectId}/magic_link/${magicLinkId}/activate`,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'magic_link_activate',
      ddh_space_id: spaceId,
      ddh_project_id: projectId,
    });

    return res;
  }

  async deactivateMagicLink(
    payload: DeactivateMagickLinkPayload,
  ): Promise<AxiosResponse<DeactivateMagicLinkResponse>> {
    const { spaceId, projectId, magicLinkId } = payload;

    const res = await this.httpClient.put<DeactivateMagicLinkResponse>(
      `/api/${spaceId}/${projectId}/magic_link/${magicLinkId}/deactivate`,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'magic_link_deactivate',
      ddh_space_id: spaceId,
      ddh_project_id: projectId,
    });

    return res;
  }

  async deleteMagicLink(
    payload: DeleteMagickLinkPayload,
  ): Promise<AxiosResponse<DeleteMagicLinkResponse>> {
    const { spaceId, projectId, magicLinkId } = payload;

    const res = await this.httpClient.delete<DeleteMagicLinkResponse>(
      `/api/${spaceId}/${projectId}/magic_link/${magicLinkId}/delete`,
    );

    return res;
  }
}

export const magicLinkApiClient = new MagicLinkApiClient();

export default { MagicLinkApiClient, magicLinkApiClient };
