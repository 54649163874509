import type {
  AllowedComponentProps,
  Component,
  VNodeProps,
  Ref,
  ComputedRef,
} from 'vue';

// // //

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComponentProps<C extends Component> = C extends new (...args: any) => any
  ? Omit<
      InstanceType<C>['$props'],
      keyof VNodeProps | keyof AllowedComponentProps
    >
  : never;

type ReactiveValue<T extends unknown> = Ref<T> | ComputedRef<T>;

// // //

export enum ModallyModalType {
  Default = 'default',
  Confirm = 'confirm',
}

export interface MdlModalIconAttrs {
  icon: string;
  size?: number | `${number}`;
}

export type ModallyOpenResult<T extends unknown> = T;

export interface ModallyModalButton {
  attrs: {
    class?: string;
    color?: string;
    variant?: 'text' | 'flat' | 'elevated' | 'tonal' | 'outlined' | 'plain';
    loading?: boolean;
    onClick?: () => void;
  };
  content: string;
}

export interface ModallyModalButtonOptions<T extends unknown> {
  attrs: {
    class?: string;
    style?: Partial<CSSStyleDeclaration>;
    color?: string;
    variant?: 'text' | 'flat' | 'elevated' | 'tonal' | 'outlined' | 'plain';
  };
  content: string;
  action: () => T | Promise<T>;
}

export interface ModallyModal {
  id: string | number;
  component: Component;
  attrs:
    | Record<string, unknown>
    | ReactiveValue<Record<string, unknown>>
    | undefined;
  value: boolean;
  buttons: ModallyModalButton[];
}

export interface ModallyOpenPayload<T extends unknown, C extends Component> {
  component?: C;
  attrs?:
    | {
        [K in keyof ComponentProps<C>]:
          | ComponentProps<C>[K]
          | ReactiveValue<ComponentProps<C>[K]>;
      }
    | ReactiveValue<ComponentProps<C>>;
  buttons?: ModallyModalButtonOptions<T>[];
  onClose?: () => T | Promise<T>;
}

export interface ModallyUseModalPayload<C extends Component> {
  component?: C;
  attrs?:
    | {
        [K in keyof ComponentProps<C>]:
          | ComponentProps<C>[K]
          | ReactiveValue<ComponentProps<C>[K]>;
      }
    | ReactiveValue<ComponentProps<C>>;
}
