export default {
  name: 'Название',
  myCollection: 'Мой набор',
  cancel: 'Отмена',
  save: 'Сохранить',
  newCollection: 'Новый набор',
  multiplierType: 'Тип мультипликатора',
  fix: 'Фиксированная сумма',
  coef: 'Коэффициент',
  periodActive: 'Период действия',
  startPeriod: 'Начало периода',
};
