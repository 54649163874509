export default {
  welcome: 'Welcome!',
  password: 'Password',
  signIn: 'Sign in',
  writeMail: 'Please enter your email',
  writePassword: 'Please enter your password',
  requiredField: 'RequiredField',
  notValidMail: 'Invalid mail',
  notCorrectData: 'Invalid data',
};
