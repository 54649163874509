export default {
  base: 'Базовый отчёт',
  saveReport: 'Сохранить отчёт',
  saveReportAsNew: 'Сохранить как новый',
  saveComplete: 'Отчёт успешно сохранён',
  editName: 'Переименование отчёта',
  editComplete: 'Название отчёта изменено',
  savingReport: 'Сохранение отчёта',
  notEmptyName: 'Название не может быть пустым',
  name: 'Название',
  placeholder: 'Мой отчёт',
  cancel: 'Отмена',
  save: 'Сохранить',
  delete: 'Удалить',
  deleteModal: {
    title: 'Удалить отчёт?',
    subtitle: 'Вы уверены, что хотите удалить отчёт: "{name}"?',
  },
  saveSettingsReport: 'Сохранить настройки отчёта?',
  saveSettingsInfo:
    'Создайте свои отчеты, выбрав фильтры и метрики, сохраните их, а затем быстро получайте доступ к данным, выбрав сохраненный отчет.',
};
