export const uriSerializeArray = (value: unknown[]): string => {
  const serializedArray = value.join(',');

  return serializedArray;
};

export const uriSerializeObject = (value: object): string => {
  const serializedObject = Object.entries(value).reduce((acc, [key, val]) => {
    if (acc) return `${acc};${key}=${val}`;

    return `${key}=${val}`;
  }, '');

  return serializedObject;
};

export const uriInitParamsString = (params?: object): string => {
  const urlSearchParamsInstance = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, val]) => {
    let serializedVal = '';

    if (Array.isArray(val)) {
      serializedVal = uriSerializeArray(val);
    } else if (typeof val === 'object' && val) {
      serializedVal = uriSerializeObject(val);
    } else {
      serializedVal = `${val}`;
    }

    if (!serializedVal) return;

    urlSearchParamsInstance.append(key, serializedVal);
  });

  const urlParamsString = urlSearchParamsInstance.toString();

  return urlParamsString;
};

export const uriInitString = (url: string, params?: object): string => {
  const urlParamsString = uriInitParamsString(params);

  const urlString = `${url}${urlParamsString ? `?${urlParamsString}` : ''}`;

  return urlString;
};

export default {
  uriSerializeArray,
  uriSerializeObject,
  uriInitParamsString,
  uriInitString,
};
