export default {
  base: 'Basic report',
  saveReport: 'Save report',
  saveReportAsNew: 'Save as new',
  saveComplete: 'The report was successfully saved',
  editName: 'Rename the report',
  editComplete: 'Report title changed',
  savingReport: 'Saving a report',
  notEmptyName: 'The name cannot be empty',
  name: 'Name',
  placeholder: 'My report',
  cancel: 'Cancel',
  save: 'Save',
  delete: 'Delete',
  deleteModal: {
    title: 'Delete report?',
    subtitle: 'Are you sure you want to delete report: "{name}"?',
  },
  saveSettingsReport: 'Сохранить настройки отчёта?',
  saveSettingsInfo:
    'Создайте свои отчеты, выбрав фильтры и метрики, сохраните их, а затем быстро получайте доступ к данным, выбрав сохраненный отчет.',
};
