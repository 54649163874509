export enum DdhTableColumnType {
  Text = 'text',
  Number = 'number',
}

export interface DdhTableColumn {
  prop: string | number;
  type?: `${DdhTableColumnType}`;
  width?: 'auto' | number | string;
  maxWidth?: number;
  minWidth?: number;
  header: {
    className?: string;
    title?:
      | string
      | number
      | {
          content: string | number;
        };
    subtitle?:
      | string
      | number
      | {
          content: string | number;
        };
    description?:
      | string
      | number
      | {
          width?: string | number;
          content: string | number;
        };
  };
  total?: {
    className?: string;
    content: string | number;
    colSpan?: number;
  };
  className?: string;
  placeholder?: string;
  sort?: boolean;
}

export interface DdhTableCell {
  prop: string | number;
  type: `${DdhTableColumnType}`;
  className: string;
  style: {
    width?: string;
    minWidth?: string;
    maxWidth?: string;
  };
  colSpan: number;
  title: {
    content: string | number;
  };
  subtitle: {
    content: string | number;
  };
  description: {
    width?: string | number;
    content: string | number;
  };
  placeholder: string;
  sort: boolean;
  value: string | number | undefined | null;
  renderValue: string | number | undefined;
}

export interface DdhTableDataItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export enum DdhTableSortingOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export interface DdhTableSortingItem {
  prop: DdhTableColumn['prop'];
  order: `${DdhTableSortingOrder}`;
}
