<template>
  <component :is="layout" class="app-layout">
    <router-view class="app-layout__content" />
    <MdlRoot />
    <NtfRoot />
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';

import { MdlRoot } from '@/packages/modally';
import { NtfRoot } from '@/packages/notify';

import { useUserMeQuery } from '@/composables';

const route = useRoute();

useUserMeQuery();

const layout = computed(() => {
  const defaultLayout = 'Default';

  return `${route.meta.layout || defaultLayout}Layout`;
});
</script>

<style lang="scss" scoped>
.app-layout {
  &__content {
    flex-grow: 1;
  }
}
</style>
