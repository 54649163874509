import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

import { groupBy } from 'lodash';

import type { NotifyPosition, NotifyNotification, NotifyGroup } from './types';

export const useNotifyStore = defineStore('notify', () => {
  const notifications = ref<NotifyNotification[]>([]);

  const groups = computed(() => {
    const notificationsByPositionMap = groupBy(notifications.value, 'position');

    const list: NotifyGroup[] = Object.entries(notificationsByPositionMap).map(
      ([key, value]) => {
        const position = key.split(',') as NotifyPosition;

        return {
          position,
          list: value,
        };
      },
    );

    return list;
  });

  const addNotification = (notification: NotifyNotification) => {
    notifications.value.push(notification);
  };

  const removeNotification = (notificationId: string | number) => {
    notifications.value = notifications.value.filter(
      (notification) => notification.id !== notificationId,
    );
  };

  return { notifications, groups, addNotification, removeNotification };
});

export default { useNotifyStore };
