import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

import { StoreName } from './types';

export const useUiStore = defineStore(StoreName.Ui, () => {
  const headerHeight = ref<number>(56);

  return {
    headerHeight: computed(() => headerHeight.value),
  };
});

export default { useUiStore };
