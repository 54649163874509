<template>
  <v-navigation-drawer
    class="app-menu"
    rail-width="52"
    rail
    expand-on-hover
    floating
    @update:rail="updateRail"
  >
    <template #prepend>
      <RouterLink
        class="app-menu__wrapper app-menu__wrapper--logo d-flex"
        :to="{
          name: RouteName.Index,
        }"
      >
        <img
          class="app-menu__logo"
          :width="logo.width"
          :height="logo.height"
          :src="logo.src"
          alt="DDH Logo"
        />
      </RouterLink>
    </template>
    <CvNavList class="pt-1">
      <CvTooltip text="В разработке" location="bottom">
        <template #activator="{ props }">
          <v-list-item title="Доступы" value="TODO: link" v-bind="props">
            <template #prepend>
              <v-icon
                style="opacity: 1"
                icon="$ddh-user-plus-01"
                color="grey-darken-1"
              />
            </template>
          </v-list-item>
        </template>
      </CvTooltip>
      <CvTooltip text="В разработке" location="bottom">
        <template #activator="{ props }">
          <v-list-item
            title="Магические ссылки"
            value="TODO: link"
            v-bind="props"
          >
            <template #prepend>
              <v-icon
                style="opacity: 1"
                icon="$ddh-link-01"
                color="grey-darken-1"
              />
            </template>
          </v-list-item>
        </template>
      </CvTooltip>
    </CvNavList>
    <template #append>
      <CvNavList class="pb-6">
        <CvTooltip text="В разработке" location="bottom">
          <template #activator="{ props }">
            <v-list-item title="Документация" value="TODO: link" v-bind="props">
              <template #prepend>
                <v-icon
                  style="opacity: 1"
                  icon="$ddh-file-06"
                  color="grey-darken-1"
                />
              </template>
            </v-list-item>
          </template>
        </CvTooltip>
        <CvTooltip text="В разработке" location="bottom">
          <template #activator="{ props }">
            <v-list-item title="Поддержка" value="TODO: link" v-bind="props">
              <template #prepend>
                <v-icon
                  style="opacity: 1"
                  icon="$ddh-help-octagon"
                  color="grey-darken-1"
                />
              </template>
            </v-list-item>
          </template>
        </CvTooltip>
      </CvNavList>
    </template>
    <v-overlay
      class="default-layout__overlay"
      :model-value="!isRail"
      persistent
      transition="fade-transition"
      z-index="1005"
    />
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';

import { RouteName } from '@/router/types';

import logoIconSvg from '@/assets/icons/logo/logo-icon.svg';
import logoSmallSvg from '@/assets/icons/logo/logo-small.svg';

const isRail = ref(true);

const logo = computed(() => {
  const width = isRail.value ? 40 : 177;
  const height = 40;

  const src = isRail.value ? logoIconSvg : logoSmallSvg;

  return {
    width,
    height,
    src,
  };
});

const updateRail = (val: boolean) => {
  isRail.value = val;
};
</script>

<style lang="scss" scoped>
.app-menu {
  &__wrapper {
    &--logo {
      padding: 8px 6px;
    }
  }
}
</style>
