import CryptoJS from 'crypto-js';

import { duplicateArrayElements } from './duplicateArrayElements';

const getColorGradient = (payload: string | number): string => {
  const hash = CryptoJS.SHA256(`${payload}`).toString(CryptoJS.enc.Hex);

  const hashNumber = parseInt(hash, 16);

  const colors = duplicateArrayElements({
    array: ['green', 'pink', 'red', 'light-blue', 'blue', 'yellow'],
    count: 7,
  });

  const index = hashNumber % colors.length;

  const color = colors[index];

  const colorGradient = `${color}-gradient`;

  return colorGradient;
};

export default { getColorGradient };
export { getColorGradient };
