import type { AxiosResponse } from 'axios';

import { gtmClient } from '@/services/gtm';

import { ApiClient } from '../../apiClient';

import type {
  FetchSpentRatePayload,
  FetchSpentRateResponse,
  CheckOverlapSpentRatePayload,
  CheckOverlapSpentRateResponse,
  CreateSpentRatePayload,
  CreateSpentRateResponse,
  UpdateSpentRatePayload,
  UpdateSpentRateResponse,
  DeleteSpentRatePayload,
  DeleteSpentRateResponse,
} from './types';

export class SpentRateApiClient extends ApiClient {
  async fetchSpentRates(
    payload: FetchSpentRatePayload,
  ): Promise<AxiosResponse<FetchSpentRateResponse>> {
    const { spaceId, projectId, adsConnectorType, adsCabinetId, segmentId } =
      payload;

    const res = await this.httpClient.get<FetchSpentRateResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}/segment/${segmentId}/spent_rate/list`,
    );

    return res;
  }

  async checkOverlapSpentRate(
    payload: CheckOverlapSpentRatePayload,
  ): Promise<AxiosResponse<CheckOverlapSpentRateResponse>> {
    const {
      spaceId,
      projectId,
      adsConnectorType,
      adsCabinetId,
      segmentId,
      data,
    } = payload;

    const res = await this.httpClient.post<CheckOverlapSpentRateResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}/segment/${segmentId}/spent_rate/check_overlap`,
      data,
    );

    return res;
  }

  async createSpentRate(
    payload: CreateSpentRatePayload,
  ): Promise<AxiosResponse<CreateSpentRateResponse>> {
    const {
      spaceId,
      projectId,
      adsConnectorType,
      adsCabinetId,
      segmentId,
      data,
    } = payload;

    const res = await this.httpClient.post<CreateSpentRateResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}/segment/${segmentId}/spent_rate/create`,
      data,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'cabinet_segment_multiplier_add',
      ddh_space_id: spaceId,
      ddh_project_id: projectId,
    });

    return res;
  }

  async updateSpentRate(
    payload: UpdateSpentRatePayload,
  ): Promise<AxiosResponse<UpdateSpentRateResponse>> {
    const {
      spaceId,
      projectId,
      adsConnectorType,
      adsCabinetId,
      segmentId,
      spentRateId,
      data,
    } = payload;

    const res = await this.httpClient.post<UpdateSpentRateResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}/segment/${segmentId}/spent_rate/${spentRateId}`,
      data,
    );

    return res;
  }

  async deleteSpentRate(
    payload: DeleteSpentRatePayload,
  ): Promise<AxiosResponse<DeleteSpentRateResponse>> {
    const {
      spaceId,
      projectId,
      adsConnectorType,
      adsCabinetId,
      segmentId,
      spentRateId,
    } = payload;

    const res = await this.httpClient.delete<DeleteSpentRateResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}/segment/${segmentId}/spent_rate/${spentRateId}`,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'cabinet_segment_multiplier_delete',
      ddh_space_id: spaceId,
      ddh_project_id: projectId,
    });

    return res;
  }
}

export const spentRateApiClient = new SpentRateApiClient();

export default { SpentRateApiClient, spentRateApiClient };
