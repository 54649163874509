<template>
  <thead class="ddh-table__head">
    <tr class="ddh-table__row ddh-table__row--head">
      <th
        v-for="cell in headerCells"
        :key="cell.prop"
        class="ddh-table__cell ddh-table__cell--head"
        :class="[
          cell.className,
          cell.subtitle.content ? 'ddh-table__cell--high' : '',
        ]"
        :style="cell.style"
      >
        <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
        <div
          :style="{ cursor: cell.sort ? 'pointer' : 'initial' }"
          @click="toggleSortingItem(cell)"
        >
          <div>
            <span style="position: relative">
              <span
                v-if="cell.title.content"
                class="d-inline-flex flex-column"
                style="
                  position: absolute;
                  top: 50%;
                  left: -4px;
                  width: 12px;
                  height: 12px;
                  transform: translate(-100%, -50%);
                "
              >
                <svg
                  v-if="cell.sort"
                  style="width: 100%; height: 100%"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    class="ddh-table__icon-sort"
                    :class="[
                      sortingItem?.prop === cell.prop &&
                      sortingItem?.order === 'asc'
                        ? 'ddh-table__icon-sort--active'
                        : '',
                    ]"
                    d="M9 5L6 1L3 5L9 5Z"
                  />
                  <path
                    class="ddh-table__icon-sort"
                    :class="[
                      sortingItem?.prop === cell.prop &&
                      sortingItem?.order === 'desc'
                        ? 'ddh-table__icon-sort--active'
                        : '',
                    ]"
                    d="M3 7L6 11L9 7H3Z"
                  />
                </svg>
              </span>
              <!-- eslint-disable vue/no-v-html -->
              <span
                :class="[
                  cell.subtitle.content
                    ? 'text-subtitle-2 text-grey-darken-4'
                    : 'text-caption text-grey-darken-1',
                ]"
                v-html="DOMPurify(`${cell.title.content}`)"
              />
              <!-- eslint-enable vue/no-v-html -->
              <CvTooltip
                v-if="cell.description.content"
                location="bottom"
                v-bind="{
                  width: cell.description.width,
                }"
              >
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="DOMPurify(`${cell.description.content}`)" />
                <template #activator="{ props: activatorSlotProps }">
                  <v-icon
                    class="ml-1"
                    size="12"
                    icon="$ddh-info-circle"
                    color="grey-darken-1"
                    v-bind="activatorSlotProps"
                  />
                </template>
              </CvTooltip>
            </span>
            <div v-if="cell.subtitle.content" style="margin-top: 2px">
              <!-- eslint-disable vue/no-v-html -->
              <span
                class="text-caption text-grey-darken-1"
                v-html="DOMPurify(`${cell.subtitle.content}`)"
              />
              <!-- eslint-enable vue/no-v-html -->
            </div>
          </div>
        </div>
      </th>
    </tr>
    <tr v-if="totalIsShown" class="ddh-table__row ddh-table__row--total">
      <th
        v-for="cell in totalCells"
        :key="cell.prop"
        class="ddh-table__cell ddh-table__cell--total text-subtitle-2 text-black"
        :class="cell.className"
        :style="cell.style"
        :colspan="cell.colSpan"
      >
        {{ cell.renderValue }}
      </th>
    </tr>
    <v-progress-linear
      v-if="loading"
      class="ddh-table__loader"
      color="primary"
      absolute
      location="bottom"
      indeterminate
    />
  </thead>
</template>

<script lang="ts" setup>
import DOMPurify from '@/utils/DOMPurify';
import type { DdhTableCell, DdhTableSortingItem } from './types';

withDefaults(
  defineProps<{
    headerCells: DdhTableCell[];
    totalCells: DdhTableCell[];
    sortingItem: DdhTableSortingItem | null;
    loading: boolean;
    totalIsShown: boolean;
  }>(),
  {
    headerCells: () => [],
    totalCells: () => [],
    sortingItem: null,
    loading: false,
    totalIsShown: false,
  },
);

const emit = defineEmits<{
  (e: 'toggle-sorting-item', val: string | number): void;
  (e: 'update-width', val: { prop: string | number; width: number }): void;
}>();

const toggleSortingItem = ({
  prop,
  sort,
}: {
  prop: string | number;
  sort: boolean;
}) => {
  if (sort) {
    emit('toggle-sorting-item', prop);
  }
};
</script>
