export default {
  page403: {
    title: 'Увы, пока у вас нет доступа',
    description:
      'Возможно мы от вас это скрываем или готовим что-то,\nно его еще рано показывать',
  },
  page404: {
    title: 'Увы, но эта страница где-то\n затерялась в галактике',
    description: 'Возможно, страница была перенесена или удалена',
  },
  returnBack: 'Вернуться назад',
  returnToMain: 'Вернуться на главную',
};
