export default {
  name: 'Название',
  myCollection: 'Мой набор',
  cancel: 'Отмена',
  save: 'Сохранить',
  newCollection: 'Новый набор',
  multiplierType: 'Тип мультипликатора',
  fix: 'Фиксированная сумма',
  coef: 'Коэффициент',
  available: 'Доступные',
  periodActive: 'Период действия',
  startPeriod: 'Начало периода',
  endPeriod: 'Конец периода',
  periodError: 'Указанный диапазон дат пересекается с ранее созданными',
  requiredField: 'Обязательное поле',
  errorDate: 'Дата начала должна быть раньше даты конца',
  errorDateRotate: 'Дата конца должна быть раньше даты начала',
  newMultiplier: 'Новый финансовый мультипликатор',
  editMultiplier: 'Редактировать финансовый мультипликатор',
  add: 'Добавить',
  multipliers: 'Финансовые мультипликаторы набора',
  segmentNoAddCost: 'У сегмента нет наценок',
  addTip: 'Вы можете добавить финансовые мультипликаторы',
  deleteMultiplier: 'Удалить мультипликатор?',
  deleteMultiplierTip: 'Все настройки мультипликатора будут утеряны',
  delete: 'Удалить',
};
