export default {
  marketingCollection: 'Advertising campaigns of the set',
  available: 'Available',
  nothingFounded: 'Nothing found',
  noCabinets: 'No campaigns',
  noResults: 'Your query returned no results,<br />try other keywords',
  allOnSegments: 'All campaigns are allocated to segments',
  allConnected: 'Bound to project',
  hereShowConnected:
    'Selected<br />campaigns will be displayed here, while this is empty',
  cancel: 'Cancel',
  save: 'Save',
};
