export default {
  addCabinet: 'Добавить кабинет',
  needAddCabinet:
    'Чтобы отобразились отчёты нужно<br />подключить рекламные кабинеты',
  noCabinets: 'У проекта нет рекламных кабинетов',
  show: 'Показать',
  hide: 'Скрыть',
  chart: 'график',
  chartSettings: 'Настроить график',
  filters: 'Фильтры',
  tableSettings: 'Настроить таблицу',
  allAvailable: 'Все доступные',
  notSet: 'Не выбраны',
  noName: 'Без названия',
  magicLink: 'Магическая ссылка',
  download: 'Скачать',
  cabinetPlural: 'нет кабинетов|{n} кабинет|{n} кабинета|{n} кабинетов',
};
