import axios, { type AxiosError } from 'axios';

import qs from 'qs';

import { uriSerializeObject } from '@/utils';

type HttpClientError<T, D> = AxiosError<T, D>;

interface ErrorWrapperBase<T, D> {
  type: 'error' | 'http-client-error' | 'unknown-error';
  error: Error | HttpClientError<T, D> | unknown;
}

interface ErrorWrapper<T, D> extends ErrorWrapperBase<T, D> {
  type: 'error';
  error: Error;
}

interface HttpClientErrorWrapper<T, D> extends ErrorWrapperBase<T, D> {
  type: 'http-client-error';
  error: HttpClientError<T, D>;
}

interface UnknownErrorWrapper<T, D> extends ErrorWrapperBase<T, D> {
  type: 'unknown-error';
  error: unknown;
}

export const httpClient = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  paramsSerializer: {
    serialize(params) {
      return qs.stringify(params, {
        arrayFormat: 'comma',
        filter: (prefix, value) => {
          let result = value;

          if (
            prefix &&
            typeof value === 'object' &&
            !Array.isArray(value) &&
            value !== null
          ) {
            result = uriSerializeObject(value);
          }

          return result;
        },
      });
    },
  },
});

export const httpClients = { mainHttpClient: httpClient };

export type HttpClients = typeof httpClients;

export const httpClientsConfigure = (
  fn: (httpClients: HttpClients) => void,
): void => fn(httpClients);

export const httpClientErrorHandler = <T, D>(payload: {
  error: Error | HttpClientError<T, D> | unknown;
  callback: (
    errorWrapper:
      | ErrorWrapper<T, D>
      | HttpClientErrorWrapper<T, D>
      | UnknownErrorWrapper<T, D>,
  ) => void;
}): void => {
  const { error, callback } = payload;

  if (axios.isAxiosError(error)) {
    callback({
      type: 'http-client-error',
      error,
    });
  } else if (error instanceof Error) {
    callback({
      type: 'error',
      error,
    });
  } else {
    callback({
      type: 'unknown-error',
      error,
    });
  }
};

export default httpClients;
