export default {
  welcome: 'Добро пожаловать!',
  password: 'Пароль',
  signIn: 'Войти',
  writeMail: 'Пожалуйста, введите вашу почту',
  writePassword: 'Пожалуйста, введите пароль',
  requiredField: 'Обязательное поле',
  notValidMail: 'Невалидная почта',
  notCorrectData: 'Неверные данные',
};
