export default {
  selectDate: 'Select dates',
  select: 'Apply',
  cancel: 'Cancel',
  timeVariation: {
    yesterday: 'Yesterday',
    week: 'Week',
    month: 'Month',
  },
  start: 'Start',
  end: 'End',
  periods: {
    yesterday: 'Yesterday',
    last7Days: 'Last 7 days',
    last14Days: 'Last 14 days',
    last30Days: 'Last 30 days',
    thisWeek: 'Current week',
    prevWeek: 'Last week',
    thisMonth: 'Current month',
    prevMonth: 'Last month',
    thisYear: 'Current Year',
    prevYear: 'Last year',
  },
};
