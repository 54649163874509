export default {
  magicLink: 'MagicLink',
  name: 'Name',
  report: 'Campaign X report',
  format: 'Format',
  description:
    'The magic link file can be downloaded and integrated into client dashboards of Power BI, Tableau and others.',
  fixDate: 'Fixed date',
  periodFormat: 'Period format',
  smoothDate: 'Floating date',
  period: 'Period',
  days: 'Number of days',
  createMagicLink: 'Create a magic link',
  allMagicLinks: 'All magic links of the project',
  support: `Let's explain how the magic link works and what it's for`,
  createComplete: 'The magic link has been created',
  howInReport: 'HowInReport',
  periods: {
    yesterday: 'Yesterday',
    thisWeek: 'This week',
    lastWeek: 'Last week',
    fromLastWeek: 'From last week',
    thisMonth: 'This month',
    lastMonth: 'Last month',
    fromLastMonth: 'From last month',
    thisYear: 'This year',
    lastYear: 'Last year',
    fromLastYear: 'From last year',
    lastNDays: 'Last N days',
    fromDate: 'From a certain date',
    toDate: 'To a certain date',
  },
  floating: 'Floating',
  daysFrom: 'Last {n} days',
  startDate: 'Start date',
  endDate: 'End date',
  from: 'From',
  to: 'To',
};
