import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

import type { RawUserMe } from '@/services/api';

import { StoreName } from './types';

export const useUserStore = defineStore(
  StoreName.User,
  () => {
    const user = ref<RawUserMe | null>(null);
    const isAuthenticated = ref(false);

    const originalUser = computed(() => {
      const result = user.value?.original_user || null;

      return result;
    });

    const setUser = (payload: RawUserMe | null) => {
      user.value = payload;
    };

    const setIsAuthenticated = (value: boolean) => {
      isAuthenticated.value = value;
    };

    return {
      user,
      originalUser,
      isAuthenticated,
      setUser,
      setIsAuthenticated,
    };
  },
  { persist: { paths: ['isAuthenticated'] } },
);

export default { useUserStore };
