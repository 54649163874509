import { createRouter, createWebHistory } from 'vue-router';

import { useUserStore, useSpacesStore } from '@/store';

import { gtmClient } from '@/services/gtm';

import { routes } from './routes';
import { RouteName } from './types';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const { user, isAuthenticated } = userStore;

  const isPrivate = !to.meta.public;
  const savePrev = to.fullPath;

  if (to.name !== RouteName.Login && isPrivate && !isAuthenticated) {
    return next({ name: RouteName.Login, query: { from: savePrev } });
  }

  if (to.name !== RouteName.LoginInit && isPrivate && !user) {
    return next({ name: RouteName.LoginInit, query: { from: savePrev } });
  }

  const spaceStore = useSpacesStore();
  // const projectStore = useProjectsStore();

  const spaceId =
    typeof to.params.spaceId === 'string'
      ? parseInt(to.params.spaceId, 10)
      : null;

  // const projectId =
  //   typeof to.params.projectId === 'string'
  //     ? parseInt(to.params.projectId, 10)
  //     : null;

  if (spaceId) {
    spaceStore.setCurrentSpaceId(spaceId);
  }

  // projectStore.setCurrentProjectId(projectId);

  return next();
});

router.afterEach((to) => {
  const spaceId =
    typeof to.params.spaceId === 'string'
      ? parseInt(to.params.spaceId, 10)
      : undefined;

  const projectId =
    typeof to.params.projectId === 'string'
      ? parseInt(to.params.projectId, 10)
      : undefined;

  gtmClient.track({
    event: 'pageview',
    ddh_space_id: spaceId,
    ddh_project_id: projectId,
  });
});

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    window.location.href = to.fullPath;
  }
});

export { routes, RouteName };

export default router;
