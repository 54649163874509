import type { AxiosResponse } from 'axios';

import { gtmClient } from '@/services/gtm';

import { ApiClient } from '../../apiClient';

import { ReportSourceKey } from '../types';

import type {
  FetchReportListPayload,
  FetchReportListResponse,
  FetchReportDataPayload,
  FetchReportDataResponse,
  FetchReportMetaPayload,
  FetchReportMetaResponse,
  FetchReportCheckPayload,
  FetchReportCheckResponse,
  GenerateReportDownloadUriPayload,
} from './types';

export class ReportApiClient extends ApiClient {
  async fetchReportList(
    payload: FetchReportListPayload,
  ): Promise<AxiosResponse<FetchReportListResponse>> {
    const { spaceId, projectId } = payload;

    const res = await this.httpClient.get<FetchReportListResponse>(
      `/api/${spaceId}/${projectId}/report/list`,
    );

    return res;
  }

  async fetchReportData(
    payload: FetchReportDataPayload,
  ): Promise<AxiosResponse<FetchReportDataResponse>> {
    const { source, spaceId, projectId, reportKey, params } = payload;

    try {
      const res = await this.httpClient.get<FetchReportDataResponse>(
        `/api/${spaceId}/${projectId}/report/${reportKey}/data`,
        { params },
      );

      switch (source) {
        case ReportSourceKey.Table:
          if (params.offset) {
            gtmClient.track({
              event: 'user_action',
              event_name: 'report_table_more',
              ddh_space_id: spaceId,
              ddh_project_id: projectId,
            });
          } else {
            gtmClient.track({
              event: 'user_action',
              event_name: 'report_table',
              ddh_space_id: spaceId,
              ddh_project_id: projectId,
            });
          }

          break;

        case ReportSourceKey.Chart:
          gtmClient.track({
            event: 'user_action',
            event_name: 'report_graph',
            ddh_space_id: spaceId,
            ddh_project_id: projectId,
          });

          break;

        default:
          break;
      }

      return res;
    } catch (e) {
      gtmClient.track({
        event: 'user_action',
        event_name: 'report_error',
        ddh_space_id: spaceId,
        ddh_project_id: projectId,
      });

      throw e;
    }
  }

  async fetchReportMeta(
    payload: FetchReportMetaPayload,
  ): Promise<AxiosResponse<FetchReportMetaResponse>> {
    const { spaceId, projectId, reportKey } = payload;

    const res = await this.httpClient.get<FetchReportMetaResponse>(
      `/api/${spaceId}/${projectId}/report/${reportKey}/meta`,
    );

    return res;
  }

  async checkReport(
    payload: FetchReportCheckPayload,
  ): Promise<AxiosResponse<FetchReportCheckResponse>> {
    const { spaceId, projectId, reportKey, params } = payload;

    const res = await this.httpClient.get<FetchReportCheckResponse>(
      `/api/${spaceId}/${projectId}/report/${reportKey}/check`,
      { params },
    );

    return res;
  }

  generateReportDownloadUri(payload: GenerateReportDownloadUriPayload): string {
    const { spaceId, projectId, reportKey, params } = payload;

    const res = this.httpClient.getUri({
      url: `/api/${spaceId}/${projectId}/report/${reportKey}/download`,
      params,
    });

    return res;
  }
}

export const reportApiClient = new ReportApiClient();

export default { ReportApiClient, reportApiClient };
