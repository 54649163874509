import type { Plugin } from 'vue';

import { get } from 'lodash';

import { useNotify } from '@/packages/notify';

import { useSpacesStore } from '@/store';

import { httpClientsConfigure, httpClientErrorHandler } from '@/services/api';

import router, { RouteName } from '@/router';
import { useRoute } from 'vue-router';

export const httpClientPluginInstall: Plugin = () => {
  const notify = useNotify();
  const spaceStore = useSpacesStore();
  const route = useRoute();

  httpClientsConfigure((httpClients) => {
    httpClients.mainHttpClient.interceptors.response.use(
      (res) => res,
      (e) => {
        if (e.response) {
          switch (e.response?.status) {
            case 401:
              // Deleted duplicate requests on login
              if (
                e.config?.url === '/auth/login' ||
                window.location.pathname === '/login'
              ) {
                throw e;
              }

              router.push({
                name: RouteName.Logout,
                query: {
                  from:
                    route?.query.from ??
                    window.location.pathname + window.location.search,
                },
              });

              break;
            case 403:
              spaceStore.setCurrentSpaceId(null);

              router.push({ name: RouteName.AccessDenied });

              break;
            case 404:
              spaceStore.setCurrentSpaceId(null);

              router.push({ name: RouteName.NotFound });

              break;

            default:
              httpClientErrorHandler({
                error: e,
                callback({ type, error }) {
                  if (type === 'http-client-error') {
                    const status = get(error, 'response.status');
                    const statusText = get(error, 'response.statusText');
                    const message = get(error, 'response.data.data.message');

                    const title =
                      status && statusText
                        ? `Ошибка: ${statusText} (${status})`
                        : 'Ошибка';

                    const content = message || '';

                    notify.push({
                      status: 'error',
                      title,
                      content,
                      elevated: true,
                      timeout: 9000,
                    });
                  }
                },
              });

              break;
          }
        }

        throw e;
      },
    );
  });
};

export default httpClientPluginInstall;
