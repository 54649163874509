import type { AxiosResponse } from 'axios';

import { ApiClient } from '../../apiClient';

import type {
  FetchUserMeResponse,
  FetchUserAuthMethodResponse,
  FetchUserAuthMethodPayload,
  FetchUserSuggestPayload,
  FetchUserSuggestResponse,
  SwitchUserResponse,
  SwitchUserPayload,
  UserLogoutResponse,
} from './types';

export class UserApiClient extends ApiClient {
  async fetchUserMe(): Promise<AxiosResponse<FetchUserMeResponse>> {
    const res = await this.httpClient.get<FetchUserMeResponse>('/api/me');

    return res;
  }

  async fetchUserAuthMethod(
    payload: FetchUserAuthMethodPayload,
  ): Promise<AxiosResponse<FetchUserAuthMethodResponse>> {
    const res = await this.httpClient.get<FetchUserAuthMethodResponse>(
      '/api/auth_method',
      { params: payload.params },
    );

    return res;
  }

  async fetchUserSuggest(
    payload: FetchUserSuggestPayload,
  ): Promise<AxiosResponse<FetchUserSuggestResponse>> {
    const res = await this.httpClient.get<FetchUserSuggestResponse>(
      '/api/user/suggest',
      { params: payload.params },
    );

    return res;
  }

  async switchUser(
    payload: SwitchUserPayload,
  ): Promise<AxiosResponse<SwitchUserResponse>> {
    const res = await this.httpClient.get<SwitchUserResponse>('/auth/switch', {
      params: payload,
    });

    return res;
  }

  async logoutUser(): Promise<AxiosResponse<UserLogoutResponse>> {
    const res = await this.httpClient.get<UserLogoutResponse>('auth/logout');

    return res;
  }
}

export const userApiClient = new UserApiClient();

export default { UserApiClient, userApiClient };
