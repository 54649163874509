export default {
  magicLink: 'Магическая ссылка',
  name: 'Название',
  report: 'Отчёт по рекламной кампании X',
  format: 'Формат',
  description:
    'Файл магической ссылки можно скачать и<br />' +
    'интегрировать в клиентские дашборды Power BI,<br />' +
    'Tableau и другие.',
  fixDate: 'Фиксированный',
  periodFormat: 'Формат периода',
  smoothDate: 'Динамический',
  period: 'Период',
  days: 'Количество дней',
  createMagicLink: 'Создать магическую ссылку',
  allMagicLinks: 'Все магические ссылки проекта',
  support:
    'Объясним как работает<br />' +
    'магическая ссылка и для<br />' +
    'чего она нужна',
  createComplete: 'Магическая ссылка создана',
  howInReport: 'Как в отчёте',
  periods: {
    yesterday: 'Вчера',
    thisWeek: 'Текущая неделя',
    lastWeek: 'Прошлая неделя',
    fromLastWeek: 'Прошлая и текущая неделя',
    thisMonth: 'Текущий месяц',
    lastMonth: 'Прошлый месяц',
    fromLastMonth: 'Прошлый и текущий месяц',
    thisYear: 'Текущий год',
    lastYear: 'Прошлый год',
    fromLastYear: 'Прошлый и текущий год',
    lastNDays: 'Последние N дней',
    fromDate: 'С определенной даты',
    toDate: 'До определенной даты',
  },
  floating: 'Плавающая',
  daysFrom:
    'С сейчас|Предыдущий {n} день|Предыдущие {n} дня|Предыдущие {n} дней',
  startDate: 'Начальная дата',
  endDate: 'Конечная дата',
  from: 'С',
  to: 'До',
};
