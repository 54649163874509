import { useUserStore } from '@/store';

enum GtmEventKey {
  Pageview = 'pageview',
  UserAction = 'user_action',
}

type GtmEventIdentity =
  | {
      event: `${GtmEventKey.Pageview}`;
    }
  | {
      event: `${GtmEventKey.UserAction}`;
      event_name: string;
    };

interface GtmEventAutosetData {
  ddh_user_id: number;
  ddh_user_type: string;
  ddh_on_behalf_of_id: number;
}

interface GtmEventRequestedData {
  ddh_space_id: number;
  ddh_project_id: number;
}

type GtmEventData = GtmEventAutosetData & GtmEventRequestedData;

type GtmEvent = GtmEventIdentity & GtmEventData;

type GtmTrackPayload = GtmEventIdentity &
  Partial<GtmEventAutosetData> & {
    [K in keyof GtmEventRequestedData]: GtmEventRequestedData[K] | undefined;
  };

class GtmClient {
  dataLayerName = 'dataLayer' as const;

  eventDataDefaultValues: GtmEventData = {
    ddh_user_id: 0,
    ddh_user_type: '-',
    ddh_on_behalf_of_id: 0,
    ddh_space_id: 0,
    ddh_project_id: 0,
  };

  normalizeEventData(payload: Partial<GtmEventData>): GtmEventData {
    const userStore = useUserStore();

    const { user, originalUser } = userStore;

    const dataDefaultValues = {
      ...this.eventDataDefaultValues,
    };

    const ddhUserId =
      originalUser?.id || user?.id || dataDefaultValues.ddh_user_id;

    const ddhUserType =
      originalUser?.type || user?.type || dataDefaultValues.ddh_user_type;

    const ddhOnBehalfOfId =
      user?.id && originalUser?.id && user.id !== originalUser.id
        ? user.id
        : dataDefaultValues.ddh_on_behalf_of_id;

    const initialData: GtmEventData = {
      ...dataDefaultValues,
      ddh_user_id: ddhUserId,
      ddh_user_type: ddhUserType,
      ddh_on_behalf_of_id: ddhOnBehalfOfId,
    };

    const keys = Object.keys(dataDefaultValues) as (keyof GtmEventData)[];

    const normalizedData = keys.reduce<GtmEventData>(
      (acc, key) => {
        const val = payload[key];

        if (val) {
          return {
            ...acc,
            [key]: val,
          };
        }

        return acc;
      },
      { ...initialData },
    );

    return normalizedData;
  }

  track(payload: GtmTrackPayload): void {
    window[this.dataLayerName] = window[this.dataLayerName] || [];

    const dataLayer = window[this.dataLayerName];

    if (!dataLayer) return;

    let eventIdentity: GtmEventIdentity | null = null;
    let eventData: Omit<GtmTrackPayload, keyof GtmEventIdentity> | null = null;

    switch (payload.event) {
      case GtmEventKey.Pageview: {
        const { event: eventKey, ...other } = payload;

        eventIdentity = {
          event: eventKey,
        };

        eventData = other;

        break;
      }

      case GtmEventKey.UserAction: {
        const { event: eventKey, event_name: eventName, ...other } = payload;

        eventIdentity = {
          event: eventKey,
          event_name: eventName,
        };

        eventData = other;

        break;
      }

      default:
        break;
    }

    if (!eventIdentity || !eventData) return;

    const normalizedEventData = this.normalizeEventData(eventData);

    const normalizedEvent: GtmEvent = {
      ...eventIdentity,
      ...normalizedEventData,
    };

    dataLayer.push(normalizedEvent as unknown as Record<string, unknown>);
  }
}

const gtmClient = new GtmClient();

export { gtmClient };
export default { gtmClient };
