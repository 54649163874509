import { FileFormatKey } from '@/services/api';

import type { FileFormatsData } from '@/types';

export const fileFormatsData: FileFormatsData = {
  [FileFormatKey.Csv]: {
    name: 'CSV',
  },
  [FileFormatKey.Tsv]: {
    name: 'TSV',
  },
  [FileFormatKey.Json]: {
    name: 'JSON',
  },
};

export default { fileFormatsData };
