import { ref } from 'vue';
import { defineStore } from 'pinia';

import { StoreName } from './types';

export const useSpacesStore = defineStore(
  StoreName.Spaces,
  () => {
    const currentSpaceId = ref<number | null>(null);

    const setCurrentSpaceId = (val: number | null) => {
      currentSpaceId.value = val;
    };

    return { currentSpaceId, setCurrentSpaceId };
  },
  { persist: { paths: ['currentSpaceId'] } },
);

export default { useSpacesStore };
