import type { AxiosResponse } from 'axios';

import type {
  FetchDashboardDataResponse,
  FetchDashboardTokenResponse,
} from '@/services/api';
import { ApiClient } from '../../apiClient';

export class DashboardApiClient extends ApiClient {
  async fetchDashboardData(payload: {
    spaceId: number;
  }): Promise<AxiosResponse<FetchDashboardDataResponse>> {
    const { spaceId } = payload;

    const res = await this.httpClient.get<FetchDashboardDataResponse>(
      `/api/${spaceId}/dashboard/data`,
    );

    return res;
  }

  async fetchDashboardToken(payload: {
    spaceId: number;
  }): Promise<AxiosResponse<FetchDashboardTokenResponse>> {
    const { spaceId } = payload;

    const res = await this.httpClient.get<FetchDashboardTokenResponse>(
      `/api/${spaceId}/dashboard/token`,
    );

    return res;
  }
}

export const dashboardApiClient = new DashboardApiClient();

export default { DashboardApiClient, dashboardApiClient };
