import ru from './ru';
import en from './en';

export enum Locales {
  RU = 'ru-RU',
  EN = 'en-US',
}
export default {
  [Locales.RU]: ru,
  [Locales.EN]: en,
};
