const numberFormat = (payload: {
  value: string | number | undefined | null;
  placeholder: string;
}): string => {
  const { value, placeholder } = payload;

  const parsedValue =
    typeof value === 'number' ? value : parseFloat(value || '');

  if (typeof parsedValue === 'number' && !Number.isNaN(parsedValue)) {
    const formatter = new Intl.NumberFormat('ru-RU', {
      minimumFractionDigits: Number.isInteger(parsedValue) ? 0 : 2,
      maximumFractionDigits: Number.isInteger(parsedValue) ? 0 : 2,
    });

    return formatter.format(parsedValue);
  }

  return `${value}` || placeholder;
};

export default { numberFormat };
export { numberFormat };
