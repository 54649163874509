/* eslint-disable max-classes-per-file */

import type { AxiosInstance } from 'axios';

import { httpClient } from './httpClient';

export class BaseApiClient {
  httpClient: AxiosInstance;

  constructor(options: { httpClient: AxiosInstance }) {
    this.httpClient = options.httpClient;
  }
}

export class ApiClient extends BaseApiClient {
  constructor() {
    super({ httpClient });
  }
}

export default { BaseApiClient, ApiClient };
