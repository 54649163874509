export default {
  selectGroup: 'Select a metric/grouping',
  nameGroup: 'Metric/group name',
  operations: {
    equal: 'Equal (=)',
    moreOrEqual: 'Greater than or equal to',
    lessOrEqual: 'Less than or equal to',
    startWith: 'Starts with',
    include: 'Contains',
  },
  active: 'Include',
  noActive: 'Exclude',
  tagNoActive: 'Exclude',
  requiredField: 'Required field',
  cantDuplicate: 'Unable to select two identical conditions',
  and: 'and',
  or: 'or',
};
