import filters from './filters';
import view from './view';
import selectors from './selectors';
import magicLinks from './magicLinks';
import settings from './settings';

export default {
  view,
  filters,
  selectors,
  magicLinks,
  settings,
};
