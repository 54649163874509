<template>
  <v-menu
    v-model="isShowSwitcher"
    :close-on-content-click="false"
    class="app-header-user"
  >
    <template #default>
      <v-form
        ref="vFormRef"
        class="app-header-user__form elevation-10"
        validate-on="submit"
        @submit.prevent="onSubmit"
      >
        <template v-if="userMe?.grants.switch && !userMe.original_user">
          <CvTextField
            v-model="switchedEmail"
            class="app-header-user__text-field"
            autofocus
            :rules="[rules.required, rules.isEmailValid, rules.isValid]"
            placeholder="pochta@datahubble.ru"
            type="email"
          >
            <template #prepend-inner>
              <v-icon size="20" color="grey-darken-1"> $ddh-at-sign </v-icon>
            </template>
          </CvTextField>
          <CvBtn
            type="submit"
            :loading="isLoading"
            class="mt-4"
            style="width: 100%"
            >Войти под пользователем</CvBtn
          >
        </template>
        <CvBtn
          v-if="userMe?.original_user"
          :loading="isLoading"
          style="width: 100%"
          @click="logoutFromSwitchedUser"
          >Выйти из просмотра от имени пользователя</CvBtn
        >
        <CvBtn
          v-else
          class="mt-2"
          color="grey-lighten-3"
          :border="false"
          variant="outlined"
          style="width: 100%"
          @click="goToLogout"
        >
          <span class="text-black">Выйти</span>
        </CvBtn>
      </v-form>
    </template>
    <template #activator="{ props, isActive }">
      <v-btn
        class="app-header-user__user-menu-button bg-grey-lighten-4"
        density="comfortable"
        variant="plain"
        color="grey-lighten-4"
        v-bind="props"
      >
        <template #default>
          <DdhAvatar :id="userMe?.id" density="comfortable">{{
            userMeInitials
          }}</DdhAvatar>
          <v-icon
            size="24"
            color="grey-darken-1"
            :icon="`mdi-menu-${isActive ? 'up' : 'down'}`"
          />
        </template>
      </v-btn>
    </template>
  </v-menu>
</template>
<script setup lang="ts">
import { DdhAvatar } from '@/packages/ddh-ui-kit';
import type { VForm, VMenu } from 'vuetify/components';
import { useUserMe } from '@/composables';
import { ref, watch } from 'vue';
import {
  useSwitchAccountMutation,
  useSwitchIsAvailableMutation,
} from '@/composables/queries/useSwitchAccountMutations';
import { isEmail } from '@/utils';
import { httpClientErrorHandler } from '@/services/api';
import { useNotify } from '@/packages/notify';
import { useLogoutMutation } from '@/composables/queries/useLogoutMutation';

const { userMe, userMeInitials } = useUserMe();

const isShowSwitcher = ref(false);
const switchedEmail = ref('');
const { mutateAsync: switchAccount } = useSwitchAccountMutation();
const { mutateAsync: checkAccountAvailable } = useSwitchIsAvailableMutation();
const { mutateAsync: logoutUser } = useLogoutMutation();
const isExistEmail = ref(true);
const isLoading = ref(false);

const rules = {
  required: (value: string) => !!value || 'Обязательное поле',
  isEmailValid: (value: string) => {
    return isEmail(value) || 'Невалидная почта';
  },
  isValid: async () => {
    return (
      isExistEmail.value ||
      'Что-то пошло не так, возможно этот пользователь еще не зарегистрирован'
    );
  },
};

const vFormRef = ref<VForm | null>(null);
const notify = useNotify();

const switchError = () =>
  notify.push({
    status: 'error',
    title: 'Некорректная почта',
    content: 'Проверьте правильность почты и повторите попытку',
    elevated: true,
    timeout: 9000,
  });

const logoutError = () =>
  notify.push({
    status: 'error',
    title: 'Некорректная почта',
    content: 'Проверьте правильность почты и повторите попытку',
    elevated: true,
    timeout: 9000,
  });

const goToLogout = async () => {
  try {
    await logoutUser();
  } catch (e) {
    logoutError();
  }
};

const onSubmit = async () => {
  if (!vFormRef.value) return;

  const { valid } = await vFormRef.value.validate();

  if (!valid) {
    return;
  }

  isLoading.value = true;

  const isAvailableAccount = await checkAccountAvailable({
    search: switchedEmail.value,
  });

  if (!isAvailableAccount) {
    isExistEmail.value = false;
    isLoading.value = false;
    await vFormRef.value.validate();

    return;
  }

  try {
    await switchAccount({ _switch_user: switchedEmail.value });
    window.location.reload();
  } catch (e) {
    httpClientErrorHandler({
      error: e,
      callback: switchError,
    });
  } finally {
    isLoading.value = false;
  }
};

watch(switchedEmail, () => {
  isExistEmail.value = true;
});

const logoutFromSwitchedUser = async () => {
  isLoading.value = true;

  try {
    await switchAccount({ _switch_user: '_exit' });
    window.location.reload();
  } catch (e) {
    httpClientErrorHandler({
      error: e,
      callback: switchError,
    });
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped lang="scss">
.app-header-user {
  &__form {
    width: fit-content;
    min-width: 290px;
    padding: 16px;
    margin-top: 4px;
    background-color: var(--shades-white);
    border: 1px solid var(--grey-lighten-4);
    border-radius: 12px;
  }

  &__user-menu-button {
    width: 60px;
    height: 36px;
    padding: 2px;
    border-radius: 28px;
    opacity: 1;

    &::before {
      background-color: transparent;
    }
  }

  &__text-field {
    width: 260px;
  }
}
</style>
