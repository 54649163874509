import { FileFormatKey, ReportKey } from '../types';

export enum RawMagicLinkStatusKey {
  Active = 1,
  Inactive = 0,
  Deleted = -1,
}

export const MagicLinkFileFormatKeyMap = <const>{
  Csv: FileFormatKey.Csv,
  Tsv: FileFormatKey.Tsv,
  Json: FileFormatKey.Json,
};

export type MagicLinkFileFormatKey =
  (typeof MagicLinkFileFormatKeyMap)[keyof typeof MagicLinkFileFormatKeyMap];

export interface RawMagicLinkParameters {
  fields: string;
  order_by: string;
  filter: string;
  custom_metadata: string;
}

export interface RawMagicLinkUser {
  id: number;
  given_name: string;
  family_name: string;
  email: string;
  last_login_at: number;
}

export interface RawMagicLink {
  id: string | null;
  name: string;
  report: ReportKey;
  report_name: string;
  status: RawMagicLinkStatusKey;
  format: MagicLinkFileFormatKey;
  parameters: RawMagicLinkParameters;
  period: string;
  access_count: number;
  last_access: number;
  created_by: RawMagicLinkUser;
  updated_by: RawMagicLinkUser;
  whitelist_users: number[];
  public: boolean;
  // grants: {
  //   activate: boolean;
  //   deactivate: boolean;
  //   delete: boolean;
  // };
}

export interface FetchMagicLinksPayload {
  spaceId: number;
  projectId: number;
}

export interface FetchMagicLinksResponse {
  ok: boolean;
  data: RawMagicLink[];
}

export interface CreateMagicLinkPayload {
  spaceId: number;
  projectId: number;
  data: {
    name: string;
    format: MagicLinkFileFormatKey;
    period: string;
    report: ReportKey;
    parameters: {
      selected_fields: string;
      order_by: string;
      filter?: string;
    };
    cabinet_ids: string;
  };
}

export interface CreateMagicLinkResponse {
  ok: boolean;
  data: RawMagicLink;
}

export interface ActivateMagickLinkPayload {
  spaceId: number;
  projectId: number;
  magicLinkId: string;
}

export interface ActivateMagicLinkResponse {
  ok: boolean;
  data: unknown; // temp
}

export interface DeactivateMagickLinkPayload {
  spaceId: number;
  projectId: number;
  magicLinkId: string;
}

export interface DeactivateMagicLinkResponse {
  ok: boolean;
  data: unknown; // temp
}

export interface DeleteMagickLinkPayload {
  spaceId: number;
  projectId: number;
  magicLinkId: string;
}

export interface DeleteMagicLinkResponse {
  ok: boolean;
  data: unknown; // temp
}
