import { useMutation, type UseMutationReturnType } from '@tanstack/vue-query';

import { userApiClient } from '@/services/api';

export type UseSwitchAccountMutation = {
  _switch_user: string;
};

export const useSwitchAccountMutation = (): UseMutationReturnType<
  unknown,
  unknown,
  UseSwitchAccountMutation,
  unknown
> => {
  return useMutation({
    mutationFn: async (variables: UseSwitchAccountMutation) => {
      const res = await userApiClient.switchUser(variables);

      return res.data.ok;
    },
  });
};

type UseSwitchIsAvailableMutation = {
  search: string;
};

export const useSwitchIsAvailableMutation = (): UseMutationReturnType<
  boolean,
  unknown,
  UseSwitchIsAvailableMutation,
  unknown
> => {
  return useMutation({
    mutationFn: async (variables: UseSwitchIsAvailableMutation) => {
      const res = await userApiClient.fetchUserSuggest({ params: variables });

      return res.data.data.some(({ email }) => variables.search === email);
    },
    onError() {
      return false;
    },
  });
};

export default { useSwitchAccountMutation };
