import type { RouterOptions } from 'vue-router';

import { t } from '@/services/translate';
import { RouteName } from './types';

const parseIntParam = (payload: string | string[]): number | number[] => {
  if (typeof payload === 'string') {
    return parseInt(payload, 10);
  }

  return payload.map((val) => parseInt(val, 10));
};

export const routes: RouterOptions['routes'] = [
  {
    path: '/',
    name: RouteName.Index,
    redirect: { name: RouteName.Spaces },
  },
  {
    path: '/login',
    children: [
      {
        path: 'initial',
        name: RouteName.LoginInit,
        component: () => import('@/views/LoginInitView.vue'),
        meta: {
          public: true,
        },
      },
      {
        path: '',
        name: RouteName.Login,
        component: () => import('@/views/LoginView.vue'),
        meta: {
          layout: 'empty',
          public: true,
        },
      },
    ],
  },
  {
    path: '/logout',
    name: RouteName.Logout,
    component: () => import('@/views/LogoutView.vue'),
    meta: {
      layout: 'empty',
      public: true,
    },
  },
  {
    path: '/spaces/:spaceId/projects/:projectId',
    name: RouteName.Project,
    redirect: { name: RouteName.ProjectReports },
    props: (route) => ({
      spaceId: parseIntParam(route.params.spaceId),
      projectId: parseIntParam(route.params.projectId),
    }),
    component: () => import('../views/ProjectView.vue'),
    children: [
      {
        path: 'reports',
        name: RouteName.ProjectReports,
        props: (route) => ({
          spaceId: parseIntParam(route.params.spaceId),
          projectId: parseIntParam(route.params.projectId),
        }),
        component: () => import('../views/ProjectReportsView.vue'),
      },
      {
        path: 'ads-cabinets',
        name: RouteName.ProjectAdsCabinets,
        props: (route) => ({
          spaceId: parseIntParam(route.params.spaceId),
          projectId: parseIntParam(route.params.projectId),
        }),
        component: () => import('../views/ProjectAdsCabinetsView.vue'),
      },
      {
        path: 'users',
        name: RouteName.ProjectUsers,
        props: (route) => ({
          spaceId: parseIntParam(route.params.spaceId),
          projectId: parseIntParam(route.params.projectId),
        }),
        component: () => import('../views/ProjectUsersView.vue'),
      },
      {
        path: 'magic-links',
        name: RouteName.ProjectMagicLinks,
        props: (route) => ({
          spaceId: parseIntParam(route.params.spaceId),
          projectId: parseIntParam(route.params.projectId),
        }),
        component: () => import('../views/ProjectMagicLinksView.vue'),
      },
      {
        path: 'settings',
        name: RouteName.ProjectSettings,
        props: (route) => ({
          spaceId: parseIntParam(route.params.spaceId),
          projectId: parseIntParam(route.params.projectId),
        }),
        component: () => import('../views/ProjectSettingsView.vue'),
      },
      {
        path: 'dashboard',
        name: RouteName.Dashboard,
        props: (route) => ({
          spaceId: parseIntParam(route.params.spaceId),
          projectId: parseIntParam(route.params.projectId),
        }),
        component: () => import('../views/DashboardView.vue'),
      },
    ],
  },
  {
    path: '/spaces',
    name: RouteName.Spaces,
    meta: {
      title: t('routes.spaces.title'),
    },
    component: () => import('../views/SpacesView.vue'),
    children: [
      {
        path: ':spaceId',
        meta: {
          title: t('routes.spaces.title'),
        },
        children: [
          {
            path: '',
            name: RouteName.Space,
            redirect: { name: RouteName.SpaceProjects },
            props: (route) => ({
              spaceId: parseIntParam(route.params.spaceId),
            }),
            component: () => import('../views/SpaceView.vue'),
            children: [
              {
                path: 'projects',
                name: RouteName.SpaceProjects,
                props: (route) => ({
                  spaceId: parseIntParam(route.params.spaceId),
                }),
                component: () => import('../views/SpaceProjectsView.vue'),
              },
              {
                path: 'users',
                name: RouteName.SpaceUsers,
                props: (route) => ({
                  spaceId: parseIntParam(route.params.spaceId),
                }),
                component: () => import('../views/SpaceUsersView.vue'),
              },
              {
                path: 'settings',
                name: RouteName.SpaceSettings,
                props: (route) => ({
                  spaceId: parseIntParam(route.params.spaceId),
                }),
                component: () => import('../views/SpaceSettingsView.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/ads-account',
    children: [
      {
        path: 'join',
        name: RouteName.AdsAccountJoin,
        meta: {
          layout: 'empty',
        },
        component: () => import('../views/AdsAccountJoinView.vue'),
      },
      {
        path: 'success',
        name: RouteName.AdsAccountSuccess,
        meta: {
          layout: 'empty',
        },
        component: () => import('../views/AdsAccountSuccessView.vue'),
      },
    ],
  },
  {
    path: '/403',
    name: RouteName.AccessDenied,
    meta: {
      public: true,
    },
    props: () => ({
      code: 403,
      title: t('routes.errorPages.page403.title'),
      description: t('routes.errorPages.page403.description'),
      returnToMain: true,
    }),
    component: () => import('../views/ErrorView.vue'),
  },
  {
    path: '/404',
    name: RouteName.NotFound,
    meta: {
      public: true,
    },
    props: () => ({
      title: t('routes.errorPages.page404.title'),
      description: t('routes.errorPages.page404.description'),
    }),
    component: () => import('../views/ErrorView.vue'),
  },
  {
    path: '/:catchAll(.*)*',
    name: RouteName.NotFound,
    meta: {
      public: true,
    },
    props: () => ({
      title: t('routes.errorPages.page404.title'),
      description: t('routes.errorPages.page404.description'),
    }),
    component: () => import('../views/ErrorView.vue'),
  },
];

export default { routes };
