import type { App } from 'vue';

import * as Sentry from '@sentry/vue';

import { VueQueryPlugin } from '@tanstack/vue-query';

import { translate } from '@/services/translate';
import store from '../store';
import router from '../router';

import { loadFonts } from './webFontLoader';
import httpClient from './httpClient';
import layouts from './layouts';
import vuetify from './vuetify';

export const registerPlugins = (app: App): void => {
  loadFonts();

  app
    .use(store)
    .use(router)
    .use(httpClient)
    .use(VueQueryPlugin, {
      queryClientConfig: {
        defaultOptions: {
          queries: { staleTime: 5 * 60 * 1000 },
        },
      },
    })
    .use(layouts)
    .use(translate)
    .use(vuetify);

  Sentry.init({
    app,
    dsn: 'https://6e7a1114eddbf48e24d25494ecb34c33@sentry.datahubble.ru/14',
    enabled: process.env.NODE_ENV === 'production',
    environment: process.env.NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'unmount', 'mount', 'update'],
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
    tracePropagationTargets: [
      'localhost',
      /^http:\/\/4ddh\.ru\/api/,
      /^https:\/\/datahubble\.ru\/api/,
    ],
    replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
};

export default { registerPlugins };
