import type { AxiosResponse } from 'axios';

import { gtmClient } from '@/services/gtm';

import { ApiClient } from '../../apiClient';

import type {
  FetchSegmentsPayload,
  FetchSegmentsResponse,
  FetchSegmentHighLevelObjectsPayload,
  FetchSegmentHighLevelObjectsResponse,
  CreateSegmentPayload,
  CreateSegmentResponse,
  UpdateSegmentPayload,
  UpdateSegmentResponse,
  DeleteSegmentPayload,
  DeleteSegmentResponse,
} from './types';

export class SegmentApiClient extends ApiClient {
  async fetchSegments(
    payload: FetchSegmentsPayload,
  ): Promise<AxiosResponse<FetchSegmentsResponse>> {
    const { spaceId, projectId, adsConnectorType, adsCabinetId } = payload;

    const res = await this.httpClient.get<FetchSegmentsResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}/segment/list`,
    );

    return res;
  }

  async fetchSegmentHighLevelObjects(
    payload: FetchSegmentHighLevelObjectsPayload,
  ): Promise<AxiosResponse<FetchSegmentHighLevelObjectsResponse>> {
    const { spaceId, projectId, adsConnectorType, adsCabinetId, segmentId } =
      payload;

    const res = await this.httpClient.get<FetchSegmentHighLevelObjectsResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}/segment/${segmentId}/high_level_objects`,
    );

    return res;
  }

  async createSegment(
    payload: CreateSegmentPayload,
  ): Promise<AxiosResponse<CreateSegmentResponse>> {
    const { spaceId, projectId, adsConnectorType, adsCabinetId, data } =
      payload;

    const res = await this.httpClient.post<CreateSegmentResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}/segment/create`,
      data,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'cabinet_segment_create',
      ddh_space_id: spaceId,
      ddh_project_id: projectId,
    });

    return res;
  }

  async updateSegment(
    payload: UpdateSegmentPayload,
  ): Promise<AxiosResponse<UpdateSegmentResponse>> {
    const {
      spaceId,
      projectId,
      adsConnectorType,
      adsCabinetId,
      segmentId,
      data,
    } = payload;

    const res = await this.httpClient.post<UpdateSegmentResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}/segment/${segmentId}`,
      data,
    );

    return res;
  }

  async deleteSegment(
    payload: DeleteSegmentPayload,
  ): Promise<AxiosResponse<DeleteSegmentResponse>> {
    const { spaceId, projectId, adsConnectorType, adsCabinetId, segmentId } =
      payload;

    const res = await this.httpClient.delete<DeleteSegmentResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}/segment/${segmentId}`,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'cabinet_segment_delete',
      ddh_space_id: spaceId,
      ddh_project_id: projectId,
    });

    return res;
  }
}

export const segmentApiClient = new SegmentApiClient();

export default { SegmentApiClient, segmentApiClient };
