export const replaceSpaceToNonBreakingSpace = (value: string): string =>
  value.replace(' ', '\u00a0');

export function convertToUnit(str: number, unit?: string): string;
export function convertToUnit(
  str: string | number | null | undefined,
  unit?: string,
): string | undefined;
export function convertToUnit(
  str: string | number | null | undefined,
  unit = 'px',
): string | undefined {
  if (str == null || str === '') {
    return undefined;
  }

  if (Number.isNaN(+str!)) {
    return String(str);
  }

  if (!Number.isFinite(+str!)) {
    return undefined;
  }

  return `${Number(str)}${unit}`;
}

export default { replaceSpaceToNonBreakingSpace };
