import { ref } from 'vue';
import { defineStore } from 'pinia';

import { StoreName } from './types';

export const useProjectsStore = defineStore(
  StoreName.Projects,
  () => {
    const currentProjectId = ref<number | null>(null);

    const setCurrentProjectId = (val: number | null) => {
      currentProjectId.value = val;
    };

    return { currentProjectId, setCurrentProjectId };
  },
  { persist: { paths: ['currentProjectId'] } },
);

export default { useProjectsStore };
