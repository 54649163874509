export default {
  page403: {
    title: 'Alas, you do not have access yet',
    description: `We may be hiding this from you or preparing something, but it's too early to show it to you yet`,
  },
  page404: {
    title: 'Alas, this page is lost in the galaxy somewhere\nearby',
    description: 'This page may have been moved or deleted',
  },
  returnBack: 'Go back',
  returnToMain: 'Return to Home',
};
