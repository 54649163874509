import { createApp } from 'vue';

import { setDefaultOptions } from 'date-fns';
import { ru } from 'date-fns/locale';

import { registerPlugins } from '@/plugins';

import App from '@/App.vue';

import '@/assets/styles/main.scss';

setDefaultOptions({ locale: ru });

const app = createApp(App);

registerPlugins(app);

app.mount('#app');
