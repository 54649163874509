import { AdsConnectorType } from '@/services/api';

import type { AdsConnectorsData } from '@/types';

import mytargetLogoPng from '../icons/platforms/my-target-logo.png';
import vkLogoPng from '../icons/platforms/vk-logo.png';
import yandexDirectLogoPng from '../icons/platforms/yandex-direct-logo.png';
import yandexMetrikaLogoPng from '../icons/platforms/yandex-metrika-logo.png';
import vkAdsLogoPng from '../icons/platforms/vk-ads-logo.png';
import weboramaLogoPng from '../icons/platforms/weborama-logo.png';
import adriverLogoPng from '../icons/platforms/adriver-logo.png';
import adservingLogoPng from '../icons/platforms/adserving-logo.png';
import hybridLogoPng from '../icons/platforms/hybrid-logo.png';
import mtsDspLogoPng from '../icons/platforms/mts-dsp-logo.png';

export const adsConnectorsData: AdsConnectorsData = {
  [AdsConnectorType.Mytarget]: {
    image: mytargetLogoPng,
  },
  [AdsConnectorType.Vk]: {
    image: vkLogoPng,
  },
  [AdsConnectorType.YandexDirect]: {
    image: yandexDirectLogoPng,
  },
  [AdsConnectorType.YandexMetrika]: {
    image: yandexMetrikaLogoPng,
  },
  [AdsConnectorType.VkAds]: {
    image: vkAdsLogoPng,
  },
  [AdsConnectorType.Weborama]: {
    image: weboramaLogoPng,
  },
  [AdsConnectorType.Adriver]: {
    image: adriverLogoPng,
  },
  [AdsConnectorType.Adserving]: {
    image: adservingLogoPng,
  },
  [AdsConnectorType.Hybrid]: {
    image: hybridLogoPng,
  },
  [AdsConnectorType.MtsDsp]: {
    image: mtsDspLogoPng,
  },
};

export default { adsConnectorsData };
