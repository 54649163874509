<template>
  <v-app-bar class="app-header" :height="headerHeight" flat>
    <template #prepend>
      <div :id="AppHeaderTeleportSlot.Prepend" />
    </template>
    <template #append>
      <div :id="AppHeaderTeleportSlot.Append" />
      <CvTooltip text="В разработке" location="bottom">
        <template #activator="{ props }">
          <CvBtn
            class="mr-3"
            v-bind="props"
            color="grey-lighten-3"
            variant="outlined"
            icon
          >
            <v-icon color="grey-darken-1">$ddh-search-md</v-icon>
          </CvBtn>
        </template>
      </CvTooltip>
      <CvTooltip text="В разработке" location="bottom">
        <template #activator="{ props }">
          <CvBtn
            class="mr-3"
            v-bind="props"
            color="grey-lighten-3"
            variant="outlined"
            icon
          >
            <v-icon color="grey-darken-1">$ddh-bell-01</v-icon>
          </CvBtn>
        </template>
      </CvTooltip>
      <AppHeaderUser />
    </template>
  </v-app-bar>
</template>

<script lang="ts" setup>
import { useUiStore } from '@/store';
import AppHeaderUser from '@/components/AppHeader/AppHeaderUser.vue';
import { AppHeaderTeleportSlot } from './types';

const { headerHeight } = useUiStore();
</script>
