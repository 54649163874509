import { ref, markRaw } from 'vue';
import { defineStore } from 'pinia';

import type { ModallyModal } from './types';

export const useModallyStore = defineStore('modally', () => {
  const modals = ref<ModallyModal[]>([]);

  const addModal = (modal: ModallyModal) => {
    // TODO: refactor typing
    (modals.value as ModallyModal[]).push({
      ...modal,
      component: markRaw(modal.component),
    });
  };

  const removeModal = (modalId: string | number) => {
    modals.value = modals.value.filter((modal) => modal.id !== modalId);
  };

  const openModal = (modalId: string | number) => {
    const foundModal = modals.value.find((modal) => modal.id === modalId);

    if (!foundModal) return;

    foundModal.value = true;
  };

  const closeModal = (modalId: string | number) => {
    const foundModal = modals.value.find((modal) => modal.id === modalId);

    if (!foundModal) return;

    foundModal.value = false;
  };

  return { modals, addModal, removeModal, openModal, closeModal };
});

export default { useModallyStore };
