<template>
  <div class="mdl-root">
    <component
      :is="modal.component"
      v-for="modal in modallyStore.modals"
      :key="modal.id"
      v-model="modal.value"
      v-bind="modal.attrs"
      :buttons="modal.buttons"
    />
  </div>
</template>

<script lang="ts" setup>
import { useModallyStore } from '../store';

const modallyStore = useModallyStore();
</script>
