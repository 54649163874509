import type { AxiosResponse } from 'axios';

import { ApiClient } from '../../apiClient';

import type {
  FetchAdsConnectorsPayload,
  FetchAdsConnectorsResponse,
} from './types';

export class AdsConnectorsApiClient extends ApiClient {
  async fetchAdsConnectors(
    payload: FetchAdsConnectorsPayload,
  ): Promise<AxiosResponse<FetchAdsConnectorsResponse>> {
    const { spaceId } = payload;

    const res = await this.httpClient.get<FetchAdsConnectorsResponse>(
      `/api/${spaceId}/connectors`,
    );

    return res;
  }
}

export const adsConnectorsApiClient = new AdsConnectorsApiClient();

export default { AdsConnectorsApiClient, adsConnectorsApiClient };
