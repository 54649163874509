import * as dateFns from 'date-fns';

export const dateFormat = (payload: {
  value: string | Date;
  format?: string;
  placeholder?: string;
}): string => {
  const { value, ...other } = payload;

  const options: Required<typeof other> = {
    format: 'dd.MM.yyyy',
    placeholder: '',
    ...other,
  };

  const date = value instanceof Date ? value : dateFns.parseISO(value);

  return dateFns.isValid(date)
    ? dateFns.format(date, options.format)
    : options.placeholder;
};

export const dateRanges = {
  today: {
    start: dateFns.startOfToday(),
    end: dateFns.endOfToday(),
  },
  yesterday: {
    start: dateFns.startOfYesterday(),
    end: dateFns.endOfYesterday(),
  },
  thisWeek: {
    start: dateFns.startOfWeek(new Date()),
    end: dateFns.endOfWeek(new Date()),
  },
  lastWeek: {
    start: dateFns.startOfWeek(dateFns.subWeeks(new Date(), 1)),
    end: dateFns.endOfWeek(dateFns.subWeeks(new Date(), 1)),
  },
  thisMonth: {
    start: dateFns.startOfMonth(new Date()),
    end: dateFns.endOfMonth(new Date()),
  },
  lastMonth: {
    start: dateFns.startOfMonth(dateFns.subMonths(new Date(), 1)),
    end: dateFns.endOfMonth(dateFns.subMonths(new Date(), 1)),
  },
  thisYear: {
    start: dateFns.startOfYear(new Date()),
    end: dateFns.endOfYear(new Date()),
  },
} as const;

export function getDateRange(name: keyof typeof dateRanges): {
  start: Date;
  end: Date;
};
export function getDateRange(
  name: keyof typeof dateRanges,
  formatString: string,
): { start: string; end: string };
export function getDateRange(
  name: keyof typeof dateRanges,
  formatString?: string,
): { start: Date; end: Date } | { start: string; end: string } {
  const range = dateRanges[name];

  if (typeof formatString === 'string') {
    return {
      start: dateFns.format(range.start, formatString),
      end: dateFns.format(range.end, formatString),
    };
  }

  return range;
}

export function getArrayedDateRange(
  name: keyof typeof dateRanges,
): [Date, Date];
export function getArrayedDateRange(
  name: keyof typeof dateRanges,
  formatString: string,
): [string, string];
export function getArrayedDateRange(
  name: keyof typeof dateRanges,
  formatString?: string,
): [Date, Date] | [string, string] {
  if (typeof formatString === 'string') {
    const range = getDateRange(name, formatString);

    return [range.start, range.end];
  }

  const range = getDateRange(name);

  return [range.start, range.end];
}

export default { dateFormat, dateRanges, getDateRange, getArrayedDateRange };
